import {
    getContactEmailAddress,
    getContactPhoneNumber,
    getContactPhoneUrl,
    getNextGenerationTextNumber
} from './getContactDetails';

/**
 * This function provides the ability to dynamically replace certain 'shortcodes' embedded within a content string
 * with some alternative content, which varies depending on the shortcode.
 *
 * This concept is based on the similar shortcodes functionality built into WordPress - see:
 * https://codex.wordpress.org/shortcode
 *
 * Only certain custom Well Online-specific shortcodes are supported by this functionality; all other shortcodes
 * are already processed by WordPress itself on the server-side before the content is sent via the REST API to
 * this app. These custom shortcodes are instead implemented here on the client-side, to avoid rare conflicts with
 * the multi-site content retrieval functionality that would occur if they were implemented on the server-side.
 *
 * The custom shortcodes supported by this functionality are:
 *
 * [WELLONLINE_PHONE_NO] - this is replaced with a link containing the correct contact phone number for this user/site
 * [WELLONLINE_NGT_NUMBER] - this is replaced with the correct Next Generation Text (NGT) number for this user/site
 * [WELLONLINE_EMAIL] - this is replaced with a link containing the correct contact email address for this user/site
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * 1. Import this function at the top of any component that needs to render content that may contain shortcodes:
 *
 * import { handleContentShortcodes } from '../../helpers/handleContentShortcodes';
 *
 * 2. Add code similar to this to the component (this code will replace shortcodes within the current page content
 * retrieved from the app state):
 *
 * const content = handleContentShortcodes(app.page.data.content, state);
 *
 * 3. Render the content using the 'sanitiseHtml()' helper function (see that function for full usage instructions):
 *
 * <div dangerouslySetInnerHTML={sanitiseHtml(content)} />
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 *
 * @param {string} content
 *      A string of content that may contain custom shortcodes. This content string has probably been obtained
 *      from a WP REST API response.
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {string}
 *      The resulting content, after all applicable shortcodes have been replaced. This content may include HTML
 *      which later needs to be sanitised using a separate function in the `sanitiseHtml` helper file.
 */
export function handleContentShortcodes(content, state) {
    let processedContent = content;
    if( ! processedContent) {
        return '';
    }
    
    processedContent = processedContent.replace(
        /\[WELLONLINE_PHONE_NO]/g,
        getPhoneLink(state)
    );
    
    processedContent = processedContent.replace(
        /\[WELLONLINE_NGT_NUMBER]/g,
        getNgtNo(state)
    );
    
    processedContent = processedContent.replace(
        /\[WELLONLINE_EMAIL]/g,
        getEmailLink(state)
    );
    
    return processedContent;
}

/**
 * This function provides the ability to dynamically replace certain additional 'shortcodes' embedded within a
 * content string with some alternative content, which varies depending on the shortcode.
 *
 * This concept is based on the similar shortcodes functionality built into WordPress - see:
 * https://codex.wordpress.org/shortcode
 *
 * Only certain custom Well Online-specific shortcodes are supported by this functionality; all other shortcodes
 * are already processed by WordPress itself on the server-side before the content is sent via the REST API to
 * this app, and/or by the separate handleContentShortcodes() function included within these file. These
 * additional custom shortcodes are instead implemented here, because otherwise the resulting HTML would be
 * stripped out by the client-side HTML sanitisation mechanism.
 *
 * The custom shortcodes supported by this functionality are:
 *
 * [NHS_LIVE_WELL_FEED] - this is replaced with an iframe containing trusted content from an NHS website (using a
 * specified predefined external URL which is not dynamically adjusted).
 *
 * -------------------
 * - IMPORTANT NOTE: -
 * -------------------
 *
 * This shortcode handling mechanism does mean that some unsanitised HTML content could potentially be rendered within
 * the user's browser. Thus support for any additional custom shortcodes should only be added if there is a high level
 * of confidence that the relevant content is safe, and is unlikely to be abused by malicious users to inject
 * dangerous content.
 *
 * We recommend using this function in conjunction with the separate  sanitiseHtmlWithShortcodes() function in the
 * `sanitiseHtml` helper file, to ensure that content is sanitised to the maximum extent possible. Please see the
 * comments at the top of that function for usage instructions...
 *
 * @param {string} sanitisedContent
 *      A string of content that may contain custom shortcodes. This content string has probably been obtained
 *      from a WP REST API response, and should have already been sanitised using one of the functions in the
 *      separate `sanitiseHtml` helper file.
 *
 * @returns {string}
 *      The resulting content, after all applicable shortcodes have been replaced. NOTE: Any replaced content has not
 *      been sanitised, so should only be rendered if there is a high level of confidence that the content is safe!
 */
export function handleExtraContentShortcodes(sanitisedContent) {
    let processedContent = sanitisedContent;
    if( ! processedContent) {
        return '';
    }
    
    processedContent = processedContent.replace(
        /\[NHS_LIVE_WELL_FEED]/g,
        '<iframe ' +
        'style="border: none; height: 1000px; width: 100%;" ' +
        'src="https://api-bridge.azurewebsites.net/livewell/?uid=YWlkYW4ud2FycmVuQGNpYy1lYXAuY28udWs=&h=1000">' +
        '</iframe>'
    );
    
    return processedContent;
}

/**
 * Output a link containing the correct contact phone number. The link URL will be in URL format (i.e. prefixed
 * with 'tel:', and with all spaces stripped out), so that the user can call the number by clicking/tapping on the
 * link, whereas the link text will contain the phone number in the original unmodified format.
 *
 * This function is intended for usage when replacing the '[WELLONLINE_PHONE_NO]' shortcode within some content.
 *
 * This displayed phone number will be based on the company phone number that is stored for the current user if
 * this is available, or the default phone number stored for the overall website if not. If the phone number is
 * not available at all, the original unmodified '[WELLONLINE_PHONE_NO]' shortcode is returned instead.
 *
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {string}
 *      A link containing the contact phone number if available, or '[WELLONLINE_PHONE_NO]' if not.
 */
function getPhoneLink(state) {
    const defaultText = '[WELLONLINE_PHONE_NO]';
    if(state.config.data.display_phone === 'no') {
        return defaultText;
    }
    
    const phone = getContactPhoneNumber(state);
    const phoneUrl = getContactPhoneUrl(state);
    
    return (phone && phoneUrl) ? '<a href="' + phoneUrl + '">' + phone + '</a>' : defaultText;
}

/**
 * Output a link containing the correct contact email address. The link URL will be in URL format (i.e. prefixed
 * with 'mailto:'), so that the user can send an email by clicking/tapping on the link, whereas the link text will
 * contain the email address in the original unmodified format.
 *
 * This function is intended for usage when replacing the '[WELLONLINE_EMAIL]' shortcode within some content.
 *
 * This displayed email address will be based on the company email address that is stored for the current user if
 * this is available, or the default email address stored for the overall website if not. If the email address is
 * not available at all, the original unmodified '[WELLONLINE_EMAIL]' shortcode is returned instead.
 *
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {string}
 *      A link containing the contact email address if available, or '[WELLONLINE_EMAIL]' if not.
 */
function getEmailLink(state) {
    const defaultText = '[WELLONLINE_EMAIL]';
    if(state.config.data.display_email === 'no') {
        return defaultText;
    }
    
    const email = getContactEmailAddress(state);
    
    return email ? '<a href="mailto:' + email + '">' + email + '</a>' : defaultText;
}

/**
 * Output the correct Next Generation Text (NGT) number.
 *
 * This function is intended for usage when replacing the '[WELLONLINE_NGT_NUMBER]' shortcode within some content.
 *
 * This displayed phone number will be based on the company NGT number that is stored for the current user if
 * this is available, or the default NGT number stored for the overall website if not. If the NGT number is
 * not available at all, the original unmodified '[WELLONLINE_NGT_NUMBER]' shortcode is returned instead.
 *
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {string}
 *      A link containing the NGT number if available, or '[WELLONLINE_NGT_NUMBER]' if not.
 */
function getNgtNo(state) {
    const ngtNo = getNextGenerationTextNumber(state);
    return ngtNo ? ngtNo : '[WELLONLINE_NGT_NUMBER]';
}