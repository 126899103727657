/**
 * @todo review & document
 */

import {IonGrid, IonRow} from "@ionic/react";
import React from "react";
import {sanitiseHtml} from "../helpers/sanitiseHtml";
import {TopicCard} from "./TopicCard";
import { TopicCardLoading } from './TopicCardLoading';

import featuredTopicPlaceholderImg from '../images/card-img-default.jpg';

const FeaturedTopics = (
    {
        heading,
        topics,
        isLoading
    }
) => {
    
    const topicsLimit = 2; const cols = [];
    
    // Assemble the featured topic cards to display if content is not still loading
    if( ! isLoading) {
        // Do not render any topic cards if there are no topics
        if(topics.length === 0) {
            return null;
        }
    
        // Loop through each row and column, assembling the topic card to render in each grid 'cell'
        for(let curTopicNum = 0; curTopicNum < topics.length; curTopicNum++) {
            // Do not assemble any further cards if we have already assembled cards for all available topics
            if(curTopicNum === topicsLimit) {
                break;
            }
        
            // Get the next available topic
            let topic = topics[curTopicNum];
        
            // Get featured image, or 'default' image if topic appears to have no featured image
            const featuredImg = topic.featured_image ? topic.featured_image : featuredTopicPlaceholderImg;
        
            // Assemble the next topic card and add it to the array of assembled columns within the current row.
            cols.push(
                <TopicCard key={topic.id} topic={topic} image={featuredImg} sizeSm={6} sizeMd={6} />
            );
        }
    } else {
        // Otherwise, if content is still loading, assemble 2 topic cards containing skeleton content
        for(let curTopicNum = 0; curTopicNum < topicsLimit; curTopicNum++) {
            cols.push(
                <TopicCardLoading key={curTopicNum} hasImage={true} sizeSm={6} sizeMd={6} />
            );
        }
    }
    
    // Render assembled topic card rows in a grid, surrounded by a full-width grey background, with a top heading
    return (
        <div className="bg-light">
            <IonGrid fixed>
                <h2 className="ion-text-center"><strong dangerouslySetInnerHTML={sanitiseHtml(heading)} /></h2>
                
                <IonGrid>
                    <IonRow>
                        {cols}
                    </IonRow>
                </IonGrid>
            </IonGrid>
        </div>
    );
    
};

export default FeaturedTopics;