/**
 * This Reducer function specifies how the app state should be transformed when data relating to the current page is
 * updated; for example when data is being retrieved from the WP REST API (see the 'usePageData' custom React hook),
 * based on the specified action type. The function creates and returns a new version of the 'state' variable, based
 * on the supplied action object data (see the page.actions.js file for the defined action objects which relate to
 * the fetching of page data).
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on a simplified version of the reducers in the 'Ionic Conference App' template (see e.g.
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/user/user.reducer.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { pageInitialData } from './pageData.init';

export function pageReducer(state, action) {
    switch (action.type) {
        case 'SET_CURRENT_PAGE_PATH':
            return {
                ...state,
                path: action.path
            };
        case 'PAGE_DATA_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
                data: {
                    ...pageInitialData,
                    template: state.data.template,
                    title: state.data.title,
                    section_title: state.data.section_title,
                    banner_image: state.data.banner_image
                }
            };
        case 'PAGE_DATA_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload
            };
        case 'PAGE_DATA_FETCH_ERROR':
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        case 'CHANGE_CURRENT_PAGE':
            return {
                ...state,
                isLoading: true,
                data: {
                    ...pageInitialData,
                    ...action.payload
                }
            };
        default:
            // Intentionally left empty - further action types may exist in other reducers
        break;
    }
}