/**
 * This Reducer function specifies how the app state should be transformed when data relating to the current user is
 * updated; for example when the user logs in or out of the app, based on the specified action type. The function
 * creates and returns a new version of the 'state' variable, based on the supplied action object data (see the
 * user.actions.js file for the defined action objects which relate to the updates of user data).
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on a simplified version of the reducers in the 'Ionic Conference App' template (see e.g.
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/user/user.reducer.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { userInitialState } from './user.init';

export function userReducer(state, action) {
    switch (action.type) {
        case 'USER_LOGIN_INIT':
            return {
                ...state,
                startedLogin: true,
                loginErrors: '',
                data: {...userInitialState.data}
            };
        case 'USER_LOGIN_ERROR':
            return {
                ...state,
                startedLogin: false,
                loginErrors: action.payload
            };
        case 'USER_LOGGED_IN':
            return {
                ...state,
                loggedIn: true,
                startedLogin: false,
                data: action.payload
            };
        case 'USER_LOGGED_OUT':
            return {
                ...state,
                loggedIn: false,
                data: {...userInitialState.data}
            };
        default:
            // Intentionally left empty - further action types may exist in other reducers
        break;
    }
}