/**
 * This file contains functionality to extract raw user JSON data (which has already been retrieved e.g. from WP's
 * REST API), converting it into a simpler format which can then be used throughout the app. If the raw data cannot
 * be extracted/formatted correctly, an error will be thrown - this then needs to be handled elsewhere in the app.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { userInitialState } from './user.init';
import { extractStandardDataProperties } from '../general/extractStandardDataProperties';

/**
 * This function converts the raw user JSON data which has already been retrieved
 * (e.g. from WP's REST API) into a simpler format which can then be used throughout the app.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * IMPORTANT NOTE:
 * Due to the way user data is stored, this data may get processed by this function more than once. Therefore it needs
 * to correctly handle both raw user data, and user data that was already formatted by this function previously.
 *
 * @param {string} username
 *      The username of the current user
 * @param {Object} rawUserData
 *      Raw user data in JSON format. This may have been retrieved e.g. from WP's REST API.
 * @returns {Object}
 *      Formatted user data, which can be used throughout the app as required.
 *
 * @throws {Error}
 *      If the user data cannot be formatted successfully
 */
export function extractFormattedUserData(username, rawUserData) {
    // Make sure a username was supplied
    if( ! username || username.length === 0) {
        throw new Error('Please specify a username.');
    }
    
    // Check if any user data has been retrieved
    if( ! rawUserData || rawUserData.length === 0) {
        throw new Error('No user data can be retrieved.');
    }
    
    // Check that all the required user data properties exist in the raw data
    if( ! rawUserData.hasOwnProperty('token') || ! rawUserData.token || rawUserData.token.length === 0 ) {
        throw new Error('Unexpected user data format: Token not specified.');
    }
    
    /* Extract all of the standard user data properties supported by the app from the raw data,
       skipping those properties that require their own dedicated data extraction mechanism.  */
    const skipProperties = ['username'];
    const formattedUserData = extractStandardDataProperties(
        rawUserData, userInitialState.data, skipProperties
    );
    
    // Add the supplied username to the formatted user data
    formattedUserData.username = username;
    
    return formattedUserData;
}