/**
 * This component renders the overall PWA, by loading the associated JavaScript and CSS dependencies,
 * and rendering the React sub-components used by this app.
 *
 * All app components are surrounded by the 'AppContextProvider', so that the app state can be retrieved
 * and updated where required throughout the app. See the files in the './data' sub-folder for more details
 * on how this works.
 *
 * The separate 'PageRouter' component is used to determine which app page to actually load; this varies
 * depending upon the current URL route and whether the user is logged in. This routing functionality
 * is located within a separate component, to ensure it has access to the app state via the 'AppContext'
 * (as described above), which is required to determine the current user log-in status.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React from 'react';
import { IonApp, setupConfig } from '@ionic/react';

/* Surround app components with app context provider, so state can be retrieved & updated throughout app */
import { AppContextProvider } from './data/AppContext';

/* Surround app components with Error Boundary component, so error page can be displayed if component errors occur */
import { ErrorBoundary } from './ErrorBoundary';

/* Use page router component to determine the correct app page to load */
import { PageRouter } from './PageRouter';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables and global styles */
import './theme/variables.css';
import './theme/global.css';

const App = () => {
    // Automatically switch the app to display in 'iOS' mode if the '?mode=ios' query string exists within the URL
    if(window.location.search && window.location.search === "?mode=ios") {
        setupConfig(
            {
                mode: 'ios'
            }
        );
    }
    
    return (
        <AppContextProvider>
            <IonApp>
                <ErrorBoundary>
                    <svg width="0" height="0">
                        <defs>
                            <linearGradient id="banner-overlay-gradient" x1="0.237" y1="0.216" x2="0.739" y2="1.114">
                                <stop offset="0" className="gradient-start" />
                                <stop offset="1" className="gradient-end" />
                            </linearGradient>
                        </defs>
                    </svg>
                    
                    <PageRouter />
                </ErrorBoundary>
            </IonApp>
        </AppContextProvider>
    );
};

export default App;
