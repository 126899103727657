/**
 * @todo review & document
 */

import React, {useContext} from 'react';

import './Footer.css';

import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import {AppContext} from "../data/AppContext";
import {call, chatbubbles, mail} from "ionicons/icons";
import {logoutUser} from "../data/user/logoutUser";
import { getMenuItems } from '../helpers/getMenu';
import { sanitiseHtml } from '../helpers/sanitiseHtml';
import { getContactEmailAddress, getContactPhoneNumber, getContactPhoneUrl } from '../helpers/getContactDetails';
import { openLiveChat } from '../helpers/openLiveChat';
import { MenuVertical } from './MenuVertical';
import cicLogoImg from '../images/cic-logo-white.png';

export const Footer = () => {
    
    // Use app context to retrieve and update the app state as required
    const { state, dispatch } = useContext(AppContext);
    
    // Get the footer component menu items
    const exploreMenuItems = getMenuItems(
        state.config.data.menus, state.config.data.menu_locations.footer
    );
    const policyMenuItems = getMenuItems(
        state.config.data.menus, state.config.data.menu_locations.lower_footer
    );
    
    // Get the contact details displayed in this component
    const phone = getContactPhoneNumber(state);
    const phoneUrl = getContactPhoneUrl(state);
    const email = getContactEmailAddress(state);
    
    return (
        <div id="footer">
            <div className="bg-dark">
                <IonGrid fixed>
                    <IonRow>
                        <IonCol size-xs="12" size-sm="4">
                            <MenuVertical
                                heading={state.config.data.text.footer_explore_menu_title}
                                menuItems={exploreMenuItems}
                            />
                        </IonCol>
                        
                        <IonCol size-xs="12" size-sm="4">
                            <MenuVertical
                                heading={state.config.data.text.footer_policies_menu_title}
                                menuItems={policyMenuItems}
                            />
                        </IonCol>
                        
                        <IonCol size-xs="12" size-sm="4">
                            {(state.config.data.display_phone !== 'no' && phone) ||
                             (state.config.data.display_live_chat !== 'no') ||
                             (state.config.data.display_email !== 'no' && email) ? (
                                 <h2>{state.config.data.text.footer_contact_section_title}</h2>
                             ) : null }
                             
                            {state.config.data.display_phone !== 'no' &&
                             phone && (
                                <p>
                                    <IonIcon icon={call} />
                                    <a href={phoneUrl}>{phone}</a>
                                </p>
                            )}
                            
                            {state.config.liveChatEnabled && (
                                <p>
                                    <IonIcon icon={chatbubbles} />
                                    <span className="live-chat-link" onClick={() => openLiveChat(state)}>
                                        {state.config.data.text.live_chat}
                                    </span>
                                </p>
                            )}
                            
                            {state.config.data.display_email !== 'no' &&
                             email && (
                                <p>
                                    <IonIcon icon={mail} />
                                    <a href={'mailto:' + email}>{state.config.data.text.contact}</a>
                                </p>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            
            <div className="bg-primary">
                <IonGrid fixed>
                    <IonRow className="ion-align-items-center">
                        <IonCol className="ion-text-center ion-text-lg-left" size-xs="12" size-lg="7" push-lg="5">
                            <button
                                type="button"
                                className="link-button"
                                onClick={() => logoutUser(dispatch)}>{state.config.data.text.logout}</button>
                        </IonCol>
                        
                        <IonCol className="ion-text-center ion-text-lg-left" size-xs="12" size-lg="5" pull-lg="7">
                            {state.config.data.display_cic_logo === 'yes' && (
                                <a
                                    href="https://www.cicwellbeing.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    id="cic-footer-logo"
                                >
                                    <img src={cicLogoImg} alt="CiC Logo" width="100" height="40" />
                                </a>
                            )}
                            
                            <span dangerouslySetInnerHTML={sanitiseHtml(state.config.data.text.copyright)} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </div>
    );
    
};