/**
 * @todo review & document
 */
import { IonContent, IonPage, IonSpinner } from '@ionic/react';
import React, { useContext } from 'react';

import { AppContext } from '../data/AppContext';

export const ConfigLoadingPage = () => {
    const {state} = useContext(AppContext);
    
    return (
        <IonPage>
            <IonContent>
                {state.config.isLoading &&
                    <p className="ion-text-center">
                        <IonSpinner />
                    </p>
                }
                {state.config.isError &&
                    <div className="ion-text-center">
                        <h1 className="error-message">Loading Error</h1>
                        <p>Apologies, this page did not load successfully. Please check whether you are currently
                           connected to the internet. If not, connect now, and then reload this page.</p>
                        <p>Otherwise, please contact us for further assistance...</p>
                    </div>
                }
            </IonContent>
        </IonPage>
    );
};
