/**
 * This file contains JS functions to set up the action objects which describe what happens when the app state
 * relating to the search functionality is changed, e.g. as a result of retrieving search results from the
 * WP REST API (see the 'fetchSearchResultsFromApi()' function). Each action object specifies the action type
 * and the associated data which will be used to update the state via the associated reducer (see the
 * search.reducer.js file).
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on a simplified version of the actions in the 'Ionic Conference App' template (see e.g.
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/user/user.actions.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export const hideSearchBar = () => (
    {
        type: 'HIDE_SEARCH_BAR'
    }
);

export const showSearchBar = () => (
    {
        type: 'SHOW_SEARCH_BAR'
    }
);

export const setSearchQuery = (payload) => (
    {
        type: 'SET_SEARCH_QUERY',
        payload: payload
    }
);

export const initNewSearchResultsFetch = () => (
    {
        type: 'SEARCH_RESULTS_FETCH_NEW_INIT'
    }
);

export const initSearchResultsPageFetch = (pageNum) => (
    {
        type: 'SEARCH_RESULTS_FETCH_PAGE_INIT',
        pageNum: pageNum
    }
);

export const setSearchResultsFetchSuccess = (payload, totalPages) => (
    {
        type: 'SEARCH_RESULTS_FETCH_SUCCESS',
        payload: payload,
        totalPages: parseInt(totalPages)
    }
);

export const setSearchResultsFetchError = () => (
    {
        type: 'SEARCH_RESULTS_FETCH_ERROR'
    }
);
