/**
 * This component renders icon 'cards' which represent the specified topic listing pages. Each card is shaded in
 * grey, includes a title and prominent icon, and links to another page within the app. The cards are displayed
 * within a 4 column grid (the cards stretch horizontally to fit the available space if there are less than 4
 * cards in a column). A heading is displayed at the top, above the grid. If content is still loading, 4 cards
 * containing 'skeleton' content will be displayed instead.
 *
 * Cards are rendered using the IonCard component (see https://ionicframework.com/docs/api/card), and Ionicons
 * are used to display the icons (see: https://ionicons.com/). The IonGrid component is used to render the grid
 * (see https://ionicframework.com/docs/api/grid and https://ionicframework.com/docs/layout/grid).
 *
 * See the code comments below for details of the 'props' which must be passed to this component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonSkeletonText
} from '@ionic/react';

/* Load all Ionicons */
import * as ionicons from 'ionicons/icons';

/* Get app context and associated actions, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Import app helper function(s) */
import { sanitiseHtml } from '../helpers/sanitiseHtml';
import { changePage } from '../helpers/changePage';

/* Get component stylesheet(s) */
import './TopicListingIconCards.css';

/**
 * The following 'props' must be passed to this component:
 *
 * @param {string} heading
 *      The textual content to display in the top heading. This text can include basic HTML.
 * @param {array} topicListings
 *      Array of JSON objects containing the details to display in each topic listing icon card - including
 *      title, icon, page link path, and content template to use if navigating to a new page. For example:
 *
 *      [
 *          {
 *              'title': 'Bullying',
 *              'link': '/work-life/bullying/',
 *              'icon': 'handLeft',
 *              'template': 'page-topic-listing'
 *           },
 *           {
 *              'title': 'Communication',
 *              'link': '/work-life/communication/',
 *              'icon': 'chatbubbles',
 *              'template': 'page-topic-listing'
 *           }
 *       ]
 *
 * @param {boolean} isLoading
 *      Is the page content still loading? If so, 4 cards containing 'skeleton' content will be displayed instead
 */
const TopicListingIconCards = (
    {
        heading,
        topicListings,
        isLoading
    }
) => {
    
    // Use app context to dispatch app state updates
    const { state, dispatch } = useContext(AppContext);
    
    const totalNumCols = 4; const rows = [];
    
    // Assemble the topic listing icon cards to display if content is not still loading
    if( ! isLoading) {
        // Do not render any icon cards if there are no topic listings
        if(topicListings.length === 0) {
            return null;
        }
    
        // Calculate the number of rows to render, based on the number of topic listings
        const totalNumRows = Math.ceil(topicListings.length / totalNumCols);
    
        // Loop through each row and column, assembling the topic listing icon card to render in each grid 'cell'
        let curTopicListingNum = 0;
        for(let curRowNum = 0; curRowNum < totalNumRows; curRowNum++) {
            // Adjust width of cards at medium screen sizes, based on number of listings & if final row is being shown
            let sizeMd = 3;
            if((curRowNum + 1) === totalNumRows) {
                let numRemainingListings = (topicListings.length - curTopicListingNum);
                sizeMd = 12 / numRemainingListings;
            }
            
            let cols = []; // Reset the content to render in the columns for the next row
            for(let curColNum = 0; curColNum < totalNumCols; curColNum++) {
                // Adjust width of cards shown at small screen sizes, based on number of listings
                let sizeSm = 6; let numRemainingListings = (topicListings.length - curTopicListingNum);
                if(numRemainingListings <= 1) {
                    sizeSm = topicListings.length % 2 === 0 ? 6 : 12;
                }
                
                // Do not assemble any further cards if we have already assembled cards for all available topic listings
                if(curTopicListingNum === topicListings.length) {
                    break;
                }
            
                // Get the next available topic listing
                let topicListing = topicListings[curTopicListingNum];
            
                // // Get featured image, or 'default' image if topic appears to have no featured image
                const icon = (topicListing.icon && ionicons[topicListing.icon]) ?
                                ionicons[topicListing.icon] :
                                ionicons['addCircle'];
            
                // Assemble next topic listing icon card and add it to array of assembled columns within current row.
                cols.push(
                    <IonCol key={topicListing.id} sizeXs={12} sizeSm={sizeSm} sizeMd={sizeMd}>
                        <IonCard
                            className="ion-text-center ion-padding topic-listing-icon-card"
                            routerLink={topicListing.link}
                            onClick={() => changePage(topicListing, state, dispatch)}
                        >
                            <IonIcon icon={icon} color="primary"/>
                            <IonCardContent>
                                <strong dangerouslySetInnerHTML={sanitiseHtml(topicListing.title)}/>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                );
            
                // Proceed to the next available topic listing
                curTopicListingNum++;
            }
        
            // Assemble the next row based on the assembled columns, and add this to the array of assembled rows.
            rows.push(
                <IonRow key={curRowNum}>
                    {cols}
                </IonRow>
            );
        }
    } else {
        // Otherwise, if content is still loading, assemble 4 topic listing icon cards containing skeleton content
        let cols = [];
        for(let curColNum = 0; curColNum < totalNumCols; curColNum++) {
            cols.push(
                <IonCol key={curColNum} sizeXs={12} sizeSm={6} sizeMd={3}>
                    <IonCard className="ion-text-center ion-padding topic-listing-icon-card">
                        <IonIcon icon={ionicons['addCircle']} color="primary"/>
                        <IonCardContent>
                            <IonSkeletonText animated style={{ height: '15px' }} />
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            );
        }
        // Add a row containing the assembled card columns
        rows.push(
            <IonRow key="0">
                {cols}
            </IonRow>
        );
    }
    
    // Render assembled topic listing icon card rows in a grid, with a top heading
    return (
        <>
            <h2 className="ion-text-center"><strong dangerouslySetInnerHTML={sanitiseHtml(heading)} /></h2>
            
            <IonGrid>
                {rows}
            </IonGrid>
        </>
    );
};

export default TopicListingIconCards;