/**
 * This component renders an inspirational quote, for display on an app page. This includes the HTML text content
 * to display the quote, as well as a circular quote image displayed on the left. A placeholder image is displayed
 * if no quote image was explicitly specified. If the quote content is still loading, 'skeleton' content based on
 * the anticipated final component layout will be displayed instead.
 *
 * This component uses the IonGrid components (see https://ionicframework.com/docs/api/grid and
 * https://ionicframework.com/docs/layout/grid) to display the quote, with 3 'columns' allocated for the image,
 * and 9 for the quote text. The maximum width of the grid is 720px, so it will be centred on larger screens.
 *
 * See the code comments below for details of the 'props' that must be passed to this component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React from 'react';
import { IonCol, IonGrid, IonImg, IonRow, IonSkeletonText } from '@ionic/react';

/* Get required custom app components */
import { SkeletonTextParagraphs } from './SkeletonTextParagraphs';

/* Import app helper function(s) */
import { sanitiseHtml } from '../helpers/sanitiseHtml';

/* Get component stylesheet(s) */
import './Quote.css';

/* Import image file asset(s) used by this component */
import quotePlaceholderImg from '../images/quote-grey-default.png';

/**
 *  The following 'props' must be passed to this component:
 *
 * @param {string} quote
 *      The textual quote content to display. Can contain special characters and basic HTML.
 * @param {string} image
 *      The complete URL of an image to display on the left hand side of the quote. This is styled to always appear
 *      as a circle/oval, even if the original image was square or rectangular. A placeholder image is displayed
 *      if no image URL is specified.
 * @param {boolean} isLoading
 *      Is the page content still loading? If so, 'skeleton' content for the quote text & image is displayed instead
 */
export const Quote = (
    {
        quote,
        image,
        isLoading
    }
) => {
    
    // Don't display anything if no quote text was specified (unless content is still loading)
    if ( ! quote && ! isLoading) {
        return null;
    }
    
    return (
        <IonGrid fixed className="quote">
            <IonRow class="ion-align-items-center">
                <IonCol size-xs="12" size-sm="3">
                    { ! isLoading ? (
                        <div className="quote-img">
                            <IonImg src={image ? image : quotePlaceholderImg} alt="Quote image" />
                        </div>
                    ) : (
                        <IonSkeletonText animated style={{
                            height: '160px',
                            width: '160px',
                            borderRadius: '50%',
                            display: 'inline-block'
                        }} />
                    ) }
                </IonCol>
                <IonCol size-xs="12" size-sm="9">
                    { ! isLoading ? (
                        <div dangerouslySetInnerHTML={sanitiseHtml(quote)} />
                    ) : (
                        <SkeletonTextParagraphs number="1" />
                    ) }
                </IonCol>
            </IonRow>
        </IonGrid>
    );
    
};