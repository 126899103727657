/**
 * @todo review & document
 */

import { userLoggedIn } from './user.actions';
import { extractFormattedUserData } from './extractFormattedUserData';
import { getCookie } from '../general/cookieStorage';

export const loadStoredUserData = async (dispatch) => {
    try {
        const rawUserData = getCookie('wellonlinepwa_user');
        if( ! rawUserData) {
            logUserLoginStatus('User is not logged in.');
            return;
        }
        
        const user = JSON.parse(decodeURIComponent(rawUserData));
        
        if(
            ! user ||
            ! user.username ||
            user.username.length === 0 ||
            ! user.token ||
            user.token.length === 0
        ) {
            logUserLoginStatus('User is not logged in.');
            return;
        }
    
        // Convert retrieved data into expected format
        const userData = extractFormattedUserData(user.username, user);
        
        dispatch(userLoggedIn(userData));
        
        logUserLoginStatus('User is logged in.');
    } catch(error) {
        // Log any storage errors to browser console
        console.error(error);
    }
};

/**
 * Log the current user login status to the browser console, if this is enabled based on the configured
 * environment variables.
 *
 * @param {string} message
 *      Current user login status message
 */
const logUserLoginStatus = (message) => {
    if(
        process.env.REACT_APP_ENABLE_LOGIN_STATUS_LOGGING &&
        process.env.REACT_APP_ENABLE_LOGIN_STATUS_LOGGING === "1"
    ) {
        console.log(message);
    }
}