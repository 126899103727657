/**
 * @todo review & document
 */

import {initUserLogin, setUserLoginError, userLoggedIn} from "./user.actions";
import { extractFormattedUserData } from './extractFormattedUserData';
import { sendStatsToApi } from '../general/sendStatsToApi';
import { setCookie } from '../general/cookieStorage';

export const loginUser = async (username, password, state, dispatch) => {
    // Set URL path for REST API request
    const apiUrlPath = '/wp-json/jwt-auth/v1/token/';
    
    dispatch(initUserLogin());
    
    try {
        if(username.length === 0 || password.length === 0) {
            throw new Error(state.config.data.text.login_empty_field_message);
        }
        
        // Wait for JSON data response to be retrieved from URL
        const response = await fetch(apiUrlPath, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    username: username,
                    password: password
                })
        });
        const result = await response.json();
        
        if( ! result.hasOwnProperty('token') || result.token.length === 0) {
            throw new Error(state.config.data.text.login_error_message);
        }
    
        // Convert retrieved data into expected format
        const userData = extractFormattedUserData(username, result);
    
        // Store the resulting user data, so the user can be logged in automatically in future
        const encodedUserData = encodeURIComponent(JSON.stringify(userData));
        setCookie('wellonlinepwa_user', encodedUserData, 90);
        
        dispatch(userLoggedIn(userData));
    
        // Send user login stats to the WP REST API
        sendUserLoginStatsToApi(userData.token, dispatch);
    } catch (error) {
        // Update app state to indicate that errors occurred when logging in the user
        dispatch(setUserLoginError(error.message));
        // Log any errors to browser console
        console.error(error);
    }
};

/**
 * If the user logged in successfully, send user login stats back to the WP REST API, using the separate
 * `sendStatsToApi()` function. This is implemented via a separate API request, to allow the user login
 * statistics to be collected seamlessly, without affecting other app functionality.
 *
 * @param {string} userToken
 *      JSON Web Token (JWT), so the server can identify which user to collect statistics for
 * @param {function} dispatch
 *      Dispatch function to use to update global application state if an authentication error response was returned
 *
 * @returns {Promise<void>}
 */
const sendUserLoginStatsToApi = (userToken, dispatch) => {
    const loginData = {
        logged_in: true
    }
    
    sendStatsToApi(loginData, 'login', userToken, dispatch);
};