/**
 * Custom React hook to retrieve page data in JSON format from WP's REST API whenever the current URL path changes.
 *
 * This hook is intended for usage within page components (i.e. React components in the 'pages' folder) which need
 * to retrieve standard page data. No parameters need to be passed to this hook, so simply call 'usePageData()'
 * from each applicable page component. Also, because the data retrieval status (including the current URL path,
 * whether data is still being loaded, whether any errors were encountered, and the latest retrieved page data) is
 * stored within the overall application state (see 'data/state.js'), and can therefore be retrieved from anywhere
 * in the app, this hook does not return any values.
 *
 * Note that Ionic may not always remove inactive page components from the DOM when users navigate to a new page.
 * This hook therefore uses the 'useIonViewWillEnter' and 'useIonViewWillLeave' hooks to keep track of whether new
 * data may be loaded from the API (See https://ionicframework.com/docs/react/lifecycle#react-lifecycle-methods).
 * In addition, the current page path is stored in the overall application state, and new data is only retrieved
 * if this path appears to have changed since it was last updated. These measures are necessary to prevent
 * unexpected duplicate data requests from being sent to the API, and to ensure a request will correctly be sent
 * if a component is active and the path changes.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router';
import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';

import { AppContext } from '../data/AppContext';
import { setCurrentPagePath } from '../data/page/page.actions';
import { fetchPageDataFromApi } from '../data/page/fetchPageDataFromApi';
import { getFullPagePathIncSlashes } from '../helpers/getPagePaths';

export const usePageData = () => {
    // Keep track of whether data is allowed to be loaded based on whether the current page component is active
    const [canLoadData, setCanLoadData] = useState(false);
    // Use app context to handle state management & updates
    const {state, dispatch} = useContext(AppContext);
    // Get current URL path
    const { pathname } = useLocation();
    
    // Check if data is allowed to be retrieved when the current URL path changes, and retrieve it from the API if so
    useEffect(() => {
        // Prepend and/or append '/' to page path if it is not present already, to ensure consistency
        const fullPagePath = getFullPagePathIncSlashes(pathname);
        
        // Don't retrieve page data if we're on a search page (a different API is used for that purpose)
        if(fullPagePath.substring(0, 7) === '/search') {
            return;
        }
        
        // Don't retrieve data unless current URL path has changed since it was last updated in application state
        if(state.page.path === fullPagePath) {
            return;
        }
        
        // Don't retrieve data unless we are currently allowed to, i.e. the current page component is active
        if( ! canLoadData) {
            return;
        }
        
        // Update the application state to store the new page URL path
        dispatch(setCurrentPagePath(fullPagePath));
        
        // Retrieve data for the current page from the API
        fetchPageDataFromApi(fullPagePath, state.user.data.token, state.config.data, dispatch);
    }, [pathname, canLoadData, dispatch, state.page.path, state.user.data.token, state.config.data]);
    
    // Allow data to be loaded when the current page component is active
    useIonViewWillLeave(() => {
        setCanLoadData(false);
    });
    
    // Stop allowing data to be loaded when the current page component is no longer active
    useIonViewWillEnter(() => {
        setCanLoadData(true);
    });
};