/**
 * @todo review & document
 */

import {
    IonButton,
    IonButtons,
    IonGrid,
    IonHeader,
    IonIcon,
    IonMenuButton,
    IonToolbar
} from "@ionic/react";
import React, { useContext } from "react";

import {AppContext} from "../data/AppContext";

import './Header.css';
import { call, chatbubbles, mail, search } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { MenuHorizontal } from './MenuHorizontal';

import { hideSearchBar, showSearchBar } from '../data/search/search.actions';
import { SearchBar } from './SearchBar';

import { getContactEmailAddress, getContactPhoneNumber, getContactPhoneUrl } from '../helpers/getContactDetails';
import { openLiveChat } from '../helpers/openLiveChat';

export const Header = () => {
    const {state, dispatch} = useContext(AppContext);
    const {data} = state.config;
    
    const phone = getContactPhoneNumber(state);
    const phoneUrl = getContactPhoneUrl(state);
    const email = getContactEmailAddress(state);
    
    return (
        <IonHeader id="site-header">
            
            <IonToolbar>
                
                <IonButtons slot="end">
                    {state.search.showSearchBtn && (
                        <IonButton
                            onClick={() => state.search.showSearchBar ?
                                           dispatch(hideSearchBar()) : dispatch(showSearchBar())}
                        >
                            <IonIcon icon={search} color="primary" />
                        </IonButton>
                    )}
                    
                    {state.config.liveChatEnabled && (
                         <IonButton onClick={() => openLiveChat(state)}>
                             <IonIcon icon={chatbubbles} color="primary" />
                         </IonButton>
                     )}
                    
                    {data.display_phone !== 'no' &&
                     phone && (
                        <IonButton onClick={() => window.open(phoneUrl, "_blank")}>
                            <IonIcon icon={call} color="primary" />
                        </IonButton>
                    )}
                    
                    {data.display_email !== 'no' &&
                     email && (
                        <IonButton onClick={() => window.open('mailto:' + email, "_blank")}>
                            <IonIcon icon={mail} color="primary" />
                        </IonButton>
                    )}
                    <IonMenuButton color="secondary" />
                </IonButtons>
                
                <IonGrid fixed>
                    {data.logo && ! data.logo_link_url && (
                        <Link to="/">
                            <img src={data.logo} alt="Well Online logo" id="site-logo" className="ion-padding" />
                        </Link>
                    )}
                    {data.logo && data.logo_link_url && (
                        <a href={data.logo_link_url} target="_blank" rel="noopener noreferrer">
                            <img src={data.logo} alt="Well Online logo" id="site-logo" className="ion-padding" />
                        </a>
                    )}
                    
                    <div id="site-header-btns-desktop" className="ion-padding">
                        {state.config.liveChatEnabled && (
                             <IonButton className="ion-margin-end" onClick={() => openLiveChat(state)}>
                                 <IonIcon slot="start" icon={chatbubbles} />
                                 {data.text.live_chat}
                             </IonButton>
                         )}
                        
                        {data.display_phone !== 'no' &&
                         phone && (
                            <IonButton className="ion-margin-end" onClick={() => window.open(phoneUrl, "_blank")}>
                                <IonIcon slot="start" icon={call} />
                                {phone}
                            </IonButton>
                        )}
    
                        {data.display_email !== 'no' &&
                         email && (
                            <IonButton onClick={() => window.open('mailto:' + email, "_blank")}>
                                <IonIcon slot="start" icon={mail} />
                                {data.text.contact}
                            </IonButton>
                        )}
                    </div>
                </IonGrid>
                
            </IonToolbar>
            
            <MenuHorizontal />
            
            <SearchBar />
            
        </IonHeader>
    );
    
};