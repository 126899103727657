/**
 * @todo review & document
 */

/* Get React/React Router/Ionic dependencies */
import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from "@ionic/react-router";

/* Get app context, so the app state can be retrieved and/or updated as required */
import { AppContext } from './data/AppContext';

/* Get the app page components that are rendered depending on the current URL route and/or the app state */
import { LoginPage } from './pages/LoginPage';
import { Page } from "./pages/Page";
import { ConfigLoadingPage } from './pages/ConfigLoadingPage';
import { SearchPage } from './pages/SearchPage';

/* Get required custom app components */
import { Menu } from './components/Menu';
import { LiveChat } from './components/LiveChat';

/* Import data retrieval function(s) */
import { fetchAppConfig } from './data/config/fetchAppConfig';
import { loadStoredUserData } from './data/user/loadStoredUserData';

/* Import app helper function(s) */
import { updateAppColours } from './helpers/updateAppColours';

export const PageRouter = () => {
    const { state, dispatch } = useContext(AppContext);
    
    useEffect(() => {
        fetchAppConfig(dispatch);
        loadStoredUserData(dispatch);
    }, [dispatch]);
    
    useEffect(() => {
        updateAppColours(state.config.data.colours);
    }, [state.config.data.colours]);
    
    if(state.config.isLoading || state.config.isError) {
        return <ConfigLoadingPage />;
    }
    
    if( ! state.user.loggedIn) {
        return <LoginPage />;
    }
    
    return (
        <IonReactRouter>
            <Menu />
            <IonRouterOutlet id="main" animated={false}>
                <Route path="/" component={Page} exact />
                <Route path="/search" component={SearchPage} exact />
                <Route path="/:name" component={Page} />
            </IonRouterOutlet>
            <LiveChat />
        </IonReactRouter>
    );
};