/**
 * Custom React hook to automatically scroll the user back to the top of a content area whilst the content of
 * a page is being loaded. This hook also provides functionality to automatically hide the search bar in the header
 * after the user has scrolled down a certain distance in the content area when on a mobile device, to maximise the
 * amount of visible content. A search button is made visible in the header when this happens, so that the search
 * bar can be made visible again if required.
 *
 * This hook is intended for usage within page components (i.e. React components in the 'pages' folder) that
 * contain an `IonContent` component with a `scrollEvents` prop set to TRUE. A boolean value indicating whether
 * content is currently loading needs to be passed into this hook - this makes it possible for the hook to be
 * used regardless of the mechanism being utilised to load the content. The hook returns a `ref` object (see
 * https://reactjs.org/docs/refs-and-the-dom.html), which needs to be assigned to the relevant `IonContent`
 * component. An `onScroll` function is also returned; this needs to be assigned to the `onIonScroll` prop of
 * the `IonContent` component.
 *
 * This hook utilises React's useEffect hook to ensure that the auto-scrolling only occurs when the `isLoading`
 * parameter value changes. Furthermore, scrolling will only take place if content is still in the process of
 * being loaded, to prevent unexpected behaviour when the loading process completes.
 *
 * The code in this hook is inspired by the sample code at: https://stackoverflow.com/a/58734987
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * You can use this custom hook within page components, by implementing code similar to the below (this example code
 * retrieves a property from the app state to find out if content is loading, but other mechanisms could be used):
 *
 * import { useAutoscrollToTopOnLoad } from '../hooks/useAutoscrollToTopOnLoad';
 *
 * const MyPage = () => {
 *     const {state} = useContext(AppContext);
 *     const {contentRef, onScroll} = useAutoscrollToTopOnLoad(state.page.isLoading);
 *     return (
 *         <IonPage>
 *             <IonContent ref={contentRef} scrollEvents={true} onIonScroll={onScroll}>
 *                 ...
 *             </IonContent>
 *         </IonPage>
 *     );
 * };
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { useContext, useEffect, useRef } from 'react';
import { AppContext } from '../data/AppContext';
import { hideSearchBar } from '../data/search/search.actions';

export const useAutoscrollToTopOnLoad = (isLoading) => {
    // Set up ref object for the content which will be auto-scrolled
    const contentRef = useRef(null);
    // Use app context to handle state management & updates
    const {state, dispatch} = useContext(AppContext);
    // Specify how long (in milliseconds) the scrolling should take.
    const scrollDuration = 500;
    
    // Add behaviour to hide the header search bar when the user has scrolled a certain distance in the content area
    function onScroll(e) {
        // Specify the minimum scroll distance (in pixels) before the search bar gets hidden
        const hideSearchBarMinScrollOffset = 200;
        
        // Make sure an event object containing the expected properties has been passed to this function
        if( ! e.detail || ! e.detail.scrollTop) {
            return;
        }
        
        // Don't hide the search bar if this function has already run (i.e. the search button is already visible)
        if(state.search.showSearchBtn) {
            return;
        }
        
        // If scrolled distance is greater than specified distance, hide search bar and also show search button
        if(e.detail.scrollTop > hideSearchBarMinScrollOffset) {
            dispatch(hideSearchBar());
        }
    }
    
    // Only run this code when the `page.isLoading` app state changes
    useEffect(() => {
        // Only scroll to top when content is still being loaded, to prevent unexpected behaviour when loading finishes
        if(isLoading && contentRef.current && typeof contentRef.current.scrollToTop !== 'undefined') {
            contentRef.current.scrollToTop(scrollDuration);
        }
    }, [isLoading]);
    
    return {contentRef, onScroll};
};