/**
 * This script implements a simple Redux-like state management pattern for React using hooks.
 * This mechanism can be used wherever the app state needs to be managed or updated.
 *
 * It is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is loosely based on the code in the 'Ionic Music Player React Demo' app
 * (see https://stackblitz.com/edit/ionic-react-demo?file=State.jsx), but refactored to use
 * a structure more similar to the code in the 'Ionic Conference App' template (see
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/state.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { combineReducers } from './combineReducers';
import { stateLogger } from './stateLogger';

import { configInitialState } from './config/config.init';
import { configReducer } from './config/config.reducer';

import { pageInitialState } from './page/page.init';
import { pageReducer } from './page/page.reducer';

import { userInitialState } from './user/user.init';
import { userReducer } from './user/user.reducer';

import { searchInitialState } from './search/search.init';
import { searchReducer } from './search/search.reducer';

// Define initial app state
export const appInitialState = {
    config: configInitialState,
    page: pageInitialState,
    user: userInitialState,
    search: searchInitialState
};

// Combine all the reducers used by the app
const reducers = combineReducers(
    {
        config: configReducer,
        page: pageReducer,
        user: userReducer,
        search: searchReducer
    }
);

// Ensure that actions that update the app state via the reducers can be logged to the browser console
export const loggedReducers = stateLogger(reducers);