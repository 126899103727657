/**
 * The functions in this file retrieve the correct contact details to display within a component, based on the
 * relevant data stored within the app state for the current user and/or the overall site.
 *
 * Please see the comment blocks above each function for further details.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/**
 * Get the correct contact phone number. This will be based on the company phone number that is stored for the
 * current user if this is available, or the default phone number stored for the overall website if not.
 *
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {string}
 *      The resulting phone number.
 */
export function getContactPhoneNumber(state) {
    return state.user.data.company_phone.length > 0 ? state.user.data.company_phone : state.config.data.default_phone;
}

/**
 * Get the correct Next Generation Text (NGT) number. This will be based on the company NGT number that is stored
 * for the current user if this is available, or the default NGT number stored for the overall website if not.
 *
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {string}
 *      The resulting NGT number.
 */
export function getNextGenerationTextNumber(state) {
    return state.user.data.company_ngt_no.length > 0 ?
                state.user.data.company_ngt_no : state.config.data.default_ngt_no;
}

/**
 * Get the correct contact phone number, in a format that browsers will recognise as a URL (and thus allow the user
 * to call the relevant number). A 'tel:' prefix is added to the start of the phone number, and any spaces will be
 * stripped out. For example, if the phone number is '0800 0384 9382', the string 'tel:080003849382' is returned.
 *
 * The retrieved phone number URL will be based on the company phone number that is stored for the current user if
 * this is available, or the default phone number stored for the overall website if not. If no phone number can be
 * retrieved at all, an empty string is returned.
 *
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {string}
 *      The resulting phone number URL, or an empty string if a phone number cannot be retrieved.
 */
export function getContactPhoneUrl(state) {
    const phone = getContactPhoneNumber(state);
    return phone ? 'tel:' + phone.replace(/\s/g,'') : '';
}

/**
 * Get the correct contact email address. This will be based on the company email address that is stored for the
 * current user if this is available, or the default email address stored for the overall website if not.
 *
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {string}
 *      The resulting email address.
 */
export function getContactEmailAddress(state) {
    return state.user.data.company_email.length > 0 ? state.user.data.company_email : state.config.data.default_email;
}