/**
 * @todo review & document
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonGrid } from '@ionic/react';

import { AppContext } from '../data/AppContext';

import { BackgroundShape } from '../components/BackgroundShape';

import { sanitiseHtml } from '../helpers/sanitiseHtml';

export const ErrorTemplate = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    
    return (
        <div className="content-container">
    
            <BackgroundShape position="left" contentTop={true} hideOnSmallScreens={true} />
            <BackgroundShape position="right" contentTop={true} />
            
            <IonGrid fixed className="ion-text-center">
                <h1 className="error-message">{state.config.data.text.page_load_error_title}</h1>
                {state.config.data.text.page_load_error_message ?
                 (
                     <div
                         dangerouslySetInnerHTML={sanitiseHtml(state.config.data.text.page_load_error_message)}
                     />
                 ) : (
                    <>
                        <p>Apologies, this page did not load successfully. Please try the following steps:</p>
    
                        <p>1. Check whether you are currently connected to the internet. If not, connect now and then
                            reload this page.</p>
                        <p>2. Please check if the page address looks correct. Let us know if you spot any errors.</p>
                        <p>3. If you still are unable to find the content you are looking for, try using the search bar
                            at the top.</p>
                    </>
                )}
            </IonGrid>
        </div>
    );
};
