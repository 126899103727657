/**
 * @todo review & document
 */

import React, { useContext } from 'react';
import { IonCol, IonGrid, IonImg, IonRow, IonSkeletonText } from '@ionic/react';
import { SkeletonTextParagraphs } from './SkeletonTextParagraphs';

import contentPlaceholderImg from '../images/home-bottom-content-default.jpg';
import { AppContext } from '../data/AppContext';
import { sanitiseHtmlWithShortcodes } from '../helpers/sanitiseHtml';

import './HomeBottomContent.css';

export const HomeBottomContent = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    const {isLoading, data} = state.page;
    
    // Don't display this component unless it is enabled within the page settings
    if(data.home_display_bottom_section !== 'yes' && ! isLoading) {
        return null;
    }
    
    return (
        <IonGrid className="home-bottom-content ion-margin-bottom">
            <IonRow className="ion-align-items-center">
                <IonCol size-xs="12" size-sm="6" className="text-content">
                    { ! isLoading ? (
                        <div dangerouslySetInnerHTML={sanitiseHtmlWithShortcodes(
                            data.home_bottom_section_content,
                            state
                        )} />
                    ) : (
                        <>
                            <IonSkeletonText animated style={{ width: '50%', height: '30px' }} />
                            <SkeletonTextParagraphs number="2" />
                        </>
                    )}
                </IonCol>
                <IonCol size-xs="12" size-sm="6">
                    { ! isLoading ? (
                        <IonImg
                            src={data.home_bottom_section_image ? data.home_bottom_section_image : contentPlaceholderImg}
                            alt="Homepage bottom image"
                        />
                    ) : (
                        <IonSkeletonText animated style={{ height: '350px' }} />
                    )}
                </IonCol>
            </IonRow>
        </IonGrid>
    );
    
};