/**
 * @todo review & document
 */
import {
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonRippleEffect,
    IonTitle,
    IonToolbar
} from '@ionic/react';

import React, {useContext} from 'react';
import { useLocation } from 'react-router-dom';
import './Menu.css';
import { AppContext } from '../data/AppContext';
import { logoutUser } from '../data/user/logoutUser';
import { getMenuItems } from '../helpers/getMenu';
import { sanitiseHtml } from '../helpers/sanitiseHtml';
import { getSectionRootPath } from '../helpers/getPagePaths';
import { changePage } from '../helpers/changePage';

export const Menu = () => {
    const { state, dispatch } = useContext(AppContext);
    
    const location = useLocation();
    const sectionRootPath = getSectionRootPath(location.pathname);
    
    const menuItems = getMenuItems(state.config.data.menus, state.config.data.menu_locations.header);
    
    return (
        <IonMenu contentId="main" type="overlay" side="end">
            <IonHeader>
                <IonToolbar>
                    <IonTitle className="ion-text-center">{state.config.data.text.main_menu_title}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {menuItems.map((item, index) => {
                        return (
                            <IonMenuToggle key={item.id} autoHide={false}>
                                {item.type !== 'custom' && item.target !== '_blank' ? (
                                    <IonItem
                                        className={sectionRootPath === item.link ? 'selected' : ''}
                                        onClick={() => changePage(item, state, dispatch)}
                                        routerLink={item.link}
                                        lines="full"
                                    >
                                        <IonLabel><span dangerouslySetInnerHTML={sanitiseHtml(item.title)} /></IonLabel>
                                    </IonItem>
                                ) : (
                                    <IonItem
                                        className={sectionRootPath === item.link ? 'selected external' : 'external'}
                                        onClick={() => window.open(item.link, "_blank")}
                                        lines="full"
                                    >
                                        <IonLabel><span dangerouslySetInnerHTML={sanitiseHtml(item.title)} /></IonLabel>
                                    </IonItem>
                                )}
                                
                            </IonMenuToggle>
                        );
                    })}
                    <IonMenuToggle autoHide={false}>
                        <IonItem
                            className="external ion-activatable"
                            onClick={() => logoutUser(dispatch)}
                            lines="full"
                        >
                            <IonLabel>{state.config.data.text.logout}</IonLabel>
                            <IonRippleEffect />
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};
