/**
 * @todo review & document
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonCol, IonGrid, IonImg, IonRow, IonSkeletonText } from '@ionic/react';

/* Get app context, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get the app components that are used by this page content template */
import { BackgroundShape } from '../components/BackgroundShape';
import FeaturedTopics from '../components/FeaturedTopics';
import TopicListingIconCards from '../components/TopicListingIconCards';
import { SkeletonTextParagraphs } from '../components/SkeletonTextParagraphs';

/* Import app helper function(s) */
import { sanitiseHtmlWithShortcodes } from '../helpers/sanitiseHtml';

/* Import image file asset(s) used by this template */
import sectionRootPlaceholderImg from '../images/featured-img-default.jpg';
import stickFiguresImg from '../images/stick-figures-3.png';

export const SectionRootTemplate = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    const {page} = state;
    
    return (
        <div className='content-container'>
            
            <BackgroundShape position="right" />
            
            <IonGrid fixed>
    
                <IonGrid>
                    <IonRow>
                        <IonCol sizeXs="12" sizeSm="6" className="ion-padding-horizontal">
                            { ! page.isLoading ? (
                                <div dangerouslySetInnerHTML={sanitiseHtmlWithShortcodes(
                                    page.data.content,
                                    state
                                )} />
                            ) : (
                                <SkeletonTextParagraphs number="2" />
                            )}
    
                            <IonImg
                                src={stickFiguresImg}
                                alt="Stick figures"
                                className="ion-padding-vertical"
                                style={{width: '15%', margin: '0 auto'}}
                            />
                        </IonCol>
                        
                        <IonCol sizeXs="12" sizeSm="6">
                            { ! page.isLoading ? (
                                <IonImg
                                    src={page.data.featured_image ? page.data.featured_image : sectionRootPlaceholderImg}
                                    alt="Section Root page"
                                />
                            ) : (
                                <IonSkeletonText animated style={{ height: '300px' }} />
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
    
                <TopicListingIconCards
                    heading={state.config.data.text.topics_title}
                    topicListings={page.data.child_pages}
                    isLoading={page.isLoading}
                />
            </IonGrid>

            <FeaturedTopics
                heading={state.config.data.text.featured_topics_title}
                topics={page.data.featured_topics}
                isLoading={page.isLoading}
            />
        </div>
    );
};
