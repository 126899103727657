/**
 * This component renders a 'card' to display for the specified topic. The card includes a title, optional
 * brief excerpt, button, and optional full-width image. When clicked/tapped, the card links to another page
 * within the app. A grid column contains the card.
 *
 * Cards are rendered using the IonCard component (see https://ionicframework.com/docs/api/card), and the
 * IonCol component surrounds each card. This component should therefore be used in conjunction with the
 * IonGrid and IonRow components (see https://ionicframework.com/docs/api/grid and
 * https://ionicframework.com/docs/layout/grid).
 *
 * See the code comments below for details of the 'props' that must be passed to this component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonImg
} from '@ionic/react';

/* Get app context and associated actions, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Import app helper function(s) */
import { sanitiseHtml } from '../helpers/sanitiseHtml';
import { changePage } from '../helpers/changePage';

/* Get component stylesheet(s) */
import './TopicCard.css';

/**
 *  The following 'props' should be passed to this component:
 *
 * @param {{title: string, link: string, excerpt: string, ...}} topic
 *      Object containing the topic title to display within the card, the app link URL path to navigate to if the
 *      card is clicked/tapped, and a brief excerpt summarising the topic content (can contain special characters
 *      and basic HTML). The excerpt is optional, and will not be shown if it is not specified. The topic object
 *      may also contain additional properties such as 'template' and 'banner_image', which will be used if the
 *      user clicks/taps on the card to navigate to a new page.
 * @param {string} image
 *      The complete URL of a full-width image to display in the card.
 *      This image is optional, and no image will be shown unless an image URL is specified.
 * @param {number} sizeSm
 *      A number from 1 to 12, indicating how wide the card should appear on small (e.g. mobile) screens. A value
 *      of 6 means the card should appear at 50% width (probably adjacent to another similar card). This number
 *      is optional, and the width will be automatically calculated if unspecified.
 * @param {number} sizeMd
 *      A number from 1 to 12, indicating how wide the card should appear on medium (e.g. tablet) screens. A value
 *      of 6 means the card should appear at 50% width (probably adjacent to another similar card). This number
 *      is optional, and the width will be automatically calculated if unspecified.
 */
export const TopicCard = (
    {
        topic,
        image,
        sizeSm,
        sizeMd
    }
) => {
    
    // Use app context to retrieve the current app state & dispatch state updates
    const { state, dispatch } = useContext(AppContext);
    
    return (
        <IonCol sizeXs={12} sizeSm={sizeSm} sizeMd={sizeMd}>
            <IonCard
                className="ion-text-center topic-card"
                routerLink={topic.link}
                onClick={() => changePage(topic, state, dispatch)}
            >
                
                {image && (
                    <IonImg src={image} alt={topic.title} />
                )}
                
                <IonCardHeader>
                    <IonCardTitle><div dangerouslySetInnerHTML={sanitiseHtml(topic.title)} /></IonCardTitle>
                </IonCardHeader>
                
                <IonCardContent>
                    {topic.excerpt && (
                        <p dangerouslySetInnerHTML={sanitiseHtml(topic.excerpt)} />
                    )}
                </IonCardContent>
                
                <IonButton>{state.config.data.text.read_more}</IonButton>
                
            </IonCard>
        </IonCol>
    );
    
};