/**
 * The initial app configuration state is defined within this file. This is used by the 'state.js' file to define the
 * overall initial application state, which ensures the app runs smoothly before all data has been fully populated.
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export const configInitialState = {
    isLoading: true,
    isError: false,
    liveChatEnabled: false,
    data: {
        title: '',
        homepage_slug: 'home',
        homepage_title: '',
        homepage_banners: [],
        logo: '',
        logo_link_url: '',
        banner_image: '',
        default_phone: '',
        default_ngt_no: '',
        default_email: '',
        display_phone: 'yes',
        display_email: 'yes',
        display_live_chat: 'yes',
        live_chat_url: '',
        live_chat_start_time: '09:00',
        live_chat_stop_time: '17:00',
        display_cic_logo: 'no',
        ga_code: '',
        menu_locations: {
            header: 0,
            footer: 0,
            lower_footer: 0
        },
        menus: [],
        colours: {
            primary: '#7FBC03',
            secondary: '#707070',
            tertiary: '#3F8B00',
            text_alternative: '#F5F5F5',
            bg_default: '#FFFFFF',
            light: '#F5F5F5',
            medium: '#EDEDED',
            dark: '#707070'
        },
        text: {
            live_chat: 'Live Chat',
            contact: 'Message Us',
            read_more: 'Learn More',
            home_breadcrumb: 'Home',
            main_menu_title: 'Menu',
            download: 'Download the Full Helpsheet',
            topics_title: 'Topics',
            featured_topics_title: 'Featured Topics',
            topic_listings_title: 'Helpsheets',
            related_topic_listings_title: 'Other Helpsheets on "[TOPIC_TITLE]"',
            footer_explore_menu_title: 'Explore',
            footer_policies_menu_title: 'Policies',
            footer_contact_section_title: 'Get In Touch',
            copyright: '&copy; Copyright CiC 2020',
            login_title: 'Log In',
            login_page: '',
            login_loading_message: 'Logging in...',
            username_field_label: 'Username',
            password_field_label: 'Password',
            login_empty_field_message: 'Please enter a username and password.',
            login_error_message: 'Please make sure you have entered a valid username and password.',
            logout: 'Log out from WellOnline',
            page_loading_message: 'Please wait...',
            page_load_error_title: 'Page Loading Error',
            page_load_error_message: '',
            search_title: 'Search',
            search_loading_message: 'Loading More Search Results...',
            search_empty_field_title: 'Enter a Search Query',
            search_empty_field_message: 'Please enter a new query into the search box at the top of the page.',
            search_load_error_title: 'Search Results Loading Error',
            search_load_error_message: '',
            search_results_title: 'Search Results for "[SEARCH_QUERY]"',
            no_search_results_message: 'No search results could be found for the query "[SEARCH_QUERY]". Please try ' +
                                       'entering a different query into the search box at the top of the page.',
        }
    },
};

export const menuItemInitialState = {
    id: 0,
    title: '',
    section_title: '',
    link: '',
    excerpt: '',
    featured_image: '',
    banner_image: '',
    template: '',
    type: 'post_type',
    target: ''
}

export const homepageBannerInitialState = {
    banner_image: '',
    title: '',
    link: ''
}