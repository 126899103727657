/**
 * This component renders an abstract semi-transparent shape that can be displayed within the background of page
 * content, as per the original site designs. The shape can be displayed on either the left or right side of the
 * page, higher up or lower down in the content area, and shown or hidden on larger and smaller screens.
 *
 * The shape is implemented as an SVG file embedded directly within the component code, to allow its appearance,
 * positioning, colour, opacity and visibility to be customised via CSS code (a CSS variable is used to adjust
 * the shape colour, depending on the configured primary colour for the site being accessed).
 *
 * Note that the shape is absolutely positioned based on the overall page content height, and thus components
 * that embed this component need to be wrapped in a relatively positioned HTML element that surrounds *all* of
 * the page content except for the header, footer and breadcrumbs. A <div className="content-container"> tag is
 * appropriate for this purpose. For example:
 *
 * <IonPage>
 *      <Header />
 *      <IonContent>
 *          <TopBanners />
 *          <Breadcrumbs />
 *          <div className="content-container">
 *
 *              <BackgroundShape position="left" hideOnSmallScreens={true} />
 *              <BackgroundShape position="right" />
 *
 *              <IonGrid fixed>
 *                  { // main page content components here... }
 *              </IonGrid>
 *          </div>
 *          <Footer />
 *      </IonContent>
 * </IonPage>
 *
 * See the code comments below for details of the 'prop(s)' that must be passed to this component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import React from 'react';

import './BackgroundShape.css';

/**
 * The following optional 'prop(s)' can be passed to this component:
 *
 * @param {string} position
 *      Set this to 'left' or 'right', to display the shape on the left or right side of the page.
 * @param {boolean} contentTop
 *      Set this to TRUE to display the shape higher up in the page content (the horizontal position may also be
 *      slightly adjusted, to display the shape closer to the content).
 * @param {boolean} hideOnSmallScreens
 *      Set this to TRUE to hide the shape on smaller (i.e. mobile device) screens. Otherwise the shape will be
 *      shown on these screens.
 * @param {boolean} hideOnLargeScreens
 *      Set this to TRUE to hide the shape on larger (i.e. tablet and desktop) screens. Otherwise the shape will be
 *      shown on these screens.
 */
export const BackgroundShape = ({
    position,
    contentTop,
    hideOnSmallScreens,
    hideOnLargeScreens
}) => {
    
    // Set default sizing and positioning, to display the shape on the right side of the page
    let svgWidth= '2810.9'; let svgHeight= '2807.123';
    let svgViewBox= '0 0 2810.9 2807.123';
    let pathTransform = 'matrix(0.259, -0.966, 0.966, 0.259, 0, 2212.817)';
    let shapeClassNames = 'background-shape right';
    
    // Override the default sizing and positioning if the shape should be shown on the left side of the page
    if(position === 'left') {
        svgWidth = '3132.067'; svgHeight= '3134.023';
        svgViewBox= '0 0 3132.067 3134.023';
        pathTransform = 'translate(3132.067 1988.584) rotate(150)';
        shapeClassNames = 'background-shape left';
    }
    
    if(hideOnSmallScreens) {
        shapeClassNames += ' mobile-hide';
    }
    
    if(hideOnLargeScreens) {
        shapeClassNames += ' desktop-hide';
    }
    
    if(contentTop) {
        shapeClassNames += ' content-top';
    }
    
    return (
        <div className={shapeClassNames}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={svgWidth}
                height={svgHeight}
                viewBox={svgViewBox}
            >
                <path
                    className="background-shape-path"
                    d="M2209.775,452.964a405.18,405.18,0,0,0,2.946-61.884C2206.6,175.793,2029.2,1.995,1813.731.017a401.027,401.027,0,0,0-245.4,80.483c-90.809,68.2-200.614,106.446-314.182,104.687q-3.167-.05-6.348-.047A401.6,401.6,0,0,0,959.842,306.248C861.347,406.914,725.772,464.62,585.2,455.568q-16.3-1.053-32.92-.792C337.59,458.228,161.627,632.335,156.114,846.882a407.4,407.4,0,0,0,2.6,57.754c18.417,157.68-21.192,315.744-102.371,452.188A400.568,400.568,0,0,0,0,1561.735c-.294,216.183,173.159,395.952,389.316,403.093,1.95.066,3.928.09,5.935.085,123.516-.365,240.247,59.186,305.348,164.115,52.732,84.993,150.245,161.2,330.089,166.97,230.009,7.388,424.461-171.294,425.073-401.325q.007-2.3-.014-4.6c-.455-52.571,33.1-98.807,82.815-115.959,157.94-54.487,271.393-204.323,271.393-380.711q0-5.833-.166-11.628a76.761,76.761,0,0,1,77.17-79.316h.982c222.537,0,402.937-180.319,402.937-402.752A400.918,400.918,0,0,0,2251.04,724.91c-40.71-84.3-52.874-179.062-41.265-271.946"
                    transform={pathTransform}
                />
            </svg>
        </div>
    );
    
};