/**
 * The initial page data is defined within this file. This is ultimately used by the 'state.js' file to define the
 * overall initial application state, which ensures the app runs smoothly before all data has been fully populated.
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */
export const pageInitialData = {
    id: 0,
    title: '',
    section_title: '',
    content: '',
    template: '',
    featured_image: '',
    banner_image: '',
    help_sheet: '',
    show_help_sheet: 'yes',
    quote: '',
    quote_image: '',
    home_display_contact_section: 'yes',
    home_contact_section_content: '',
    home_display_phone_number: 'yes',
    home_display_live_chat: 'yes',
    home_display_message_link: 'yes',
    home_display_bottom_section: 'yes',
    home_bottom_section_content: '',
    home_bottom_section_image: '',
    child_pages: [],
    sibling_pages: [],
    ancestor_pages: [],
    featured_topics: []
};

export const relatedPageInitialData = {
    id: 0,
    title: '',
    section_title: '',
    link: '',
    excerpt: '',
    template: '',
    featured_image: '',
    banner_image: '',
    icon: ''
};