/**
 * This file implements functionality to retrieve search results in JSON format from WP's REST API.
 *
 * Information related to these search results (including whether the results are still being loaded, whether
 * any errors were encountered, and the latest retrieved results) is stored within the overall application
 * state (see 'data/state.js'), and can therefore be retrieved from anywhere in the app where needed.
 *
 * This functionality includes support for pagination of the retrieved search results; up to 10 results are returned
 * per search 'page' request - the results page number must be specified within the function call. The current page
 * number will be stored in the app state before the results are loaded. After a response is successfully retrieved,
 * the app state will be updated to store the total number of available results pages for the relevant search query.
 * If a page number of '1' is  specified, it is assumed that this is a new search query, and all previously
 * retrieved results are discarded. Otherwise, any new search results are appended to the existing results.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import {
    initNewSearchResultsFetch,
    initSearchResultsPageFetch,
    setSearchResultsFetchError,
    setSearchResultsFetchSuccess
} from './search.actions';
import { handleUserAuthenticationErrors } from '../../helpers/handleUserAuthenticationErrors';
import { extractFormattedSearchResults } from './extractFormattedSearchResults';

/**
 * Asynchronous function to retrieve search results in JSON format from the WP REST API.
 *
 * @param {string} searchQuery
 *      The search query entered by the user. Search results will be retrieved based on this query.
 * @param {number} pageNum
 *      The number of the search results page to retrieve results for. Up to 10 results are returned per 'page'
 *      (starting at page 1). If the page number is greater than 1, any new results will be appended to the
 *      existing previously retrieved results; otherwise it is assumed that this is a new search query, and
 *      any previous results will be discarded.
 * @param {string} userToken
 *      JSON Web Token (JWT), to ensure user is allowed to access the search results
 * @param {function} dispatch
 *      Dispatch function to use to update global application state when search result retrieval status changes
 *
 * @returns {Promise<void>}
 */
export const fetchSearchResultsFromApi = async (searchQuery, pageNum, userToken, dispatch) => {
    // Set URL path for REST API request
    const apiUrlPath = '/wp-json/wp/v2/search/?search=';
    
    if(searchQuery === '') {
        return; // Don't fetch any search results if no query was specified
    }
    
    // Update app state to indicate that search result fetching is in progress
    if(pageNum === 1) {
        // If the specified page number is page 1, assume we're fetching results for a completely new search query
        dispatch(initNewSearchResultsFetch());
    } else {
        // Otherwise assume we're loading a new 'page' of results for a previous query
        dispatch(initSearchResultsPageFetch(pageNum));
    }
    
    try {
        // Wait for JSON data response to be retrieved from URL
        const response = await fetch(apiUrlPath + encodeURIComponent(searchQuery) + '&page=' + pageNum, {
            headers: {
                authorization: 'Bearer ' + userToken
            }
        });
        const result = await response.json();
        
        // Handle any errors relating to the authentication of the current user
        await handleUserAuthenticationErrors(result, dispatch);
        
        // Convert retrieved search results into expected format
        const searchResults = extractFormattedSearchResults(result);
        
        /* Find out how many pages of search results there are - see
           https://developer.wordpress.org/rest-api/using-the-rest-api/pagination/ */
        const totalResultPages = response.headers.get('x-wp-totalpages');
        
        // Update app state to keep track of the successfully retrieved search results
        dispatch(setSearchResultsFetchSuccess(searchResults, totalResultPages));
    } catch (error) {
        // Update app state to indicate that errors occurred when retrieving the search results
        dispatch(setSearchResultsFetchError());
        // Log any search result retrieval errors to browser console
        console.error(error);
    }
};