/**
 * @todo review & document
 */

import React, {useContext} from 'react';

import './Breadcrumbs.css';

import { IonGrid, IonSkeletonText } from "@ionic/react";
import {AppContext} from "../data/AppContext";
import { Link, useLocation } from "react-router-dom";
import { sanitiseHtml } from '../helpers/sanitiseHtml';
import { changePage } from '../helpers/changePage';

export const Breadcrumbs = () => {
    
    const { state, dispatch } = useContext(AppContext);
    const { data } = state.page;
    
    const location = useLocation();
    if(location.pathname === '/' || state.page.isError) {
        return null;
    }
    
    if(state.page.isLoading) {
        return (
            <IonGrid fixed id="site-breadcrumbs" className="ion-text-center ion-padding-top">
                <IonSkeletonText animated
                         style={{ width: '30%', display: 'inline-block' }} />
            </IonGrid>
        )
    }
    
    return (
        <IonGrid fixed id="site-breadcrumbs" className="ion-text-center ion-padding-top">
                <Link to="/">{state.config.data.text.home_breadcrumb}</Link>
                
                {data.ancestor_pages.map((page, index) => {
                    return (
                        <span key={page.id}>
                            &nbsp;&gt;&nbsp;
                            <Link to={page.link} onClick={() => changePage(page, state, dispatch)}>
                                <span dangerouslySetInnerHTML={sanitiseHtml(page.title)} />
                            </Link>
                        </span>
                    );
                })}
        
                &nbsp;&gt;&nbsp;
                <strong dangerouslySetInnerHTML={sanitiseHtml(data.title)} />
        </IonGrid>
    );
    
};