/**
 * @todo review & document
 */

import { IonGrid, IonRow } from "@ionic/react";
import React, { useContext } from "react";
import { TopicCard } from "./TopicCard";
import { TopicCardLoading } from './TopicCardLoading';

import ctaPlaceholderImg from '../images/card-img-default.jpg';
import { getMenuItems } from '../helpers/getMenu';
import { AppContext } from '../data/AppContext';

export const HomeTopCTAs = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    
    const items = getMenuItems(state.config.data.menus, state.config.data.menu_locations.header);
    if(items.length === 0 && ! state.page.isLoading) {
        return null;
    }
    
    const ctasLimit = 3; let numDisplayedCtas = 0; const cols = [];
    const ctaSize = items.length >= 4 /* account for the skipped 'home' item */ ? 4 : 6;
    
    for(let curItemNum = 0; curItemNum < items.length; curItemNum++) {
        // Do not assemble any further cards if we have already assembled cards for all available topics
        if(numDisplayedCtas === ctasLimit) {
            break;
        }
    
        // Get the next available item
        let item = items[curItemNum];
    
        if(item.type === 'custom' || item.target === '_blank' || item.link === '/') {
            continue;
        }
        // Get featured image, or 'default' image if item appears to have no featured image
        const featuredImg = item.featured_image ? item.featured_image : ctaPlaceholderImg;
    
        // Assemble the next item card and add it to the array of assembled columns within the current row.
        cols.push(
            <TopicCard key={item.id} topic={item} image={featuredImg} sizeSm={ctaSize} sizeMd={ctaSize} />
        );
        
        numDisplayedCtas++;
    }
    
    return (
        <IonGrid>
    
            { ! state.page.isLoading ? (
                <IonRow>
                    {cols}
                </IonRow>
            ) : (
                <IonRow>
                    <TopicCardLoading hasImage={true} sizeSm={4} sizeMd={4} />
                    <TopicCardLoading hasImage={true} sizeSm={4} sizeMd={4} />
                    <TopicCardLoading hasImage={true} sizeSm={4} sizeMd={4} />
                </IonRow>
            )}
            
        </IonGrid>
    );
};