/**
 * This file contains JS functions to set up the action objects which describe what happens when the app state
 * relating to the current user is changed, e.g. as a result of logging in or out of the app. Each action object
 * specifies the action type and the associated data which will be used to update the state via the associated
 * reducer (see the user.reducer.js file).
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on a simplified version of the actions in the 'Ionic Conference App' template (see e.g.
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/user/user.actions.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export const initUserLogin = () => (
    {
        type: 'USER_LOGIN_INIT'
    }
);

export const setUserLoginError = (error) => (
    {
        type: 'USER_LOGIN_ERROR',
        payload: error
    }
);

export const userLoggedIn = (user) => (
    {
        type: 'USER_LOGGED_IN',
        payload: user
    }
);

export const userLoggedOut = () => (
    {
        type: 'USER_LOGGED_OUT',
    }
);