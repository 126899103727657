/**
 * This file implements functionality to send app usage statistics as JSON-encoded POST data to custom WP REST API
 * endpoints. The functionality in this file is fairly generic, to allow different kinds of stats to be submitted
 * to slightly different REST API endpoints.
 *
 * Statistics are collected via separate API requests instead of collecting them as part of the main data retrieval
 * API requests (implemented elsewhere), to allow the main data retrieval responses to be cached via service workers
 * etc (and thus not necessarily require a round trip to the server), without interfering with the stats collection.
 *
 * If any errors occur, these are logged to the browser console, however no further action is taken, to avoid
 * interfering with the standard app functionality. Similarly, the stats collection functionality is asynchronous,
 * so that it can take place in the background after the main data has been retrieved, to ensure this functionality
 * does not affect normal app usage.
 *
 * The functionality within this file is self-contained, and does not need to store any info within the app state
 * (aside from the standard handling of any user authentication errors after the stats were submitted).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { handleUserAuthenticationErrors } from '../../helpers/handleUserAuthenticationErrors';

/**
 * Asynchronous function to send app usage statistics as JSON-encoded POST data to a WP REST API endpoint.
 *
 * @param {Object} statsData
 *      An object which will be sent to the API as JSON POST data, and used to generate statistics.
 *      The data in this object may vary depending on the type of statistics being collected.
 * @param {string} statsEndpoint
 *      The name of the endpoint used to store the relevant statistics, e.g. 'page'
 * @param {string} userToken
 *      JSON Web Token (JWT), so the server can identify which user to collect statistics for
 * @param {function} dispatch
 *      Dispatch function to use to update global application state if an authentication error response was returned
 *
 * @returns {Promise<void>}
 */
export const sendStatsToApi = async (statsData, statsEndpoint, userToken, dispatch) => {
    // Set URL path for REST API request (i.e. a base stats endpoint path, followed by a custom endpoint name)
    const apiUrlPath = '/wp-json/page_user_statistics/v1/' + statsEndpoint + '/';
    
    try {
        // Send stats; then wait for JSON data response to be retrieved from URL
        const response = await fetch(apiUrlPath, {
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + userToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(statsData) // JSON-encode the supplied stats data
        });
        const result = await response.json();
        
        // Handle any errors relating to the authentication of the current user
        await handleUserAuthenticationErrors(result, dispatch);
    
        // Log details if the response date has an unexpected format
        if( ! result.hasOwnProperty('success') && result.success !== true) {
            console.warn('Unexpected ' + statsEndpoint + ' stats response:');
            console.warn(result);
            return;
        }
        
        // Log the successful collection of stats, if this is enabled based on the configured environment variables
        if(
            process.env.REACT_APP_ENABLE_STATS_SUCCESS_LOGGING &&
            process.env.REACT_APP_ENABLE_STATS_SUCCESS_LOGGING === "1"
        ) {
            console.log(statsEndpoint + ' stats collected successfully');
        }
    } catch (error) {
        // Log any stats collection errors to browser console
        console.warn(statsEndpoint + ' stats collection error:');
        console.warn(error);
    }
};