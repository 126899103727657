/**
 * This component renders a specified number of blocks containing skeleton text that are set up to resemble
 * paragraphs. This makes it straightforward to simulate large areas of text on a page while content is still
 * in the process of being loaded.
 *
 * Each block consists of multiple `IonSkeletonText` components of different widths (see
 * https://ionicframework.com/docs/api/skeleton-text), and an extra blank line is left between each
 * 'paragraph' block.
 *
 * See the code comments below for details of the 'prop(s)' that must be passed to this component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React from 'react';
import { IonSkeletonText } from '@ionic/react';

/**
 * The following 'prop(s)' must be passed to this component:
 *
 * @param {number} number
 *      Integer that specifies how many 'paragraphs' of skeleton text should be displayed.
 */
export const SkeletonTextParagraphs = ({number}) => {
    
    const paras = [];
    for(let curParaNum = 1; curParaNum <= number; curParaNum++) {
        paras.push(
            <React.Fragment key={curParaNum}>
                <p>
                    <IonSkeletonText animated />
                </p>
                <p>
                    <IonSkeletonText animated style={{ width: '80%' }} />
                </p>
                <p>
                    <IonSkeletonText animated style={{ width: '60%' }} />
                    {curParaNum < number && <br />}
                </p>
            </React.Fragment>
        );
    }
    
    return paras;
};