/**
 * @todo review & document
 */
import React, { useContext } from 'react';
import { IonButton, IonContent, IonPage } from '@ionic/react';

import { AppContext } from '../data/AppContext';
import { logoutUser } from '../data/user/logoutUser';

export const ComponentErrorPage = () => {
    const {state, dispatch} = useContext(AppContext);
    
    async function onLogOutBtnClick() {
        await logoutUser(dispatch);
        // Reload page because error boundary surrounds all components, so login page isn't shown automatically
        window.location.reload();
    }
    
    return (
        <IonPage>
            <IonContent>
                <div className="ion-text-center">
                    <h1 className="error-message">Error</h1>
    
                    <p>Apologies, an unexpected error occurred. Please try the following steps:</p>
    
                    <p>1. Check whether you are currently connected to the internet. If not, please connect now.</p>
                    <p>2. Try reloading the page.</p>
                    <p>3. If you are logged in, try logging out and then back in again.</p>
                    <p>4. Try clearing your browser history, or visiting this site in
                        Private/Incognito mode, or using a different web browser.</p>
                    <p>5. If none of the above steps work, please contact us for further assistance.</p>
    
                    {state.user.loggedIn && (
                        <IonButton onClick={onLogOutBtnClick}>Log out</IonButton>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};
