import dompurify from 'dompurify';
import { handleContentShortcodes, handleExtraContentShortcodes } from './handleContentShortcodes';

/**
 * This function sanitises the specified HTML, so that it can be rendered more safely by the browser, using React's
 * 'dangerouslySetInnerHTML' attribute (see https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml) -
 * helping to protect against XSS attacks.
 *
 * The function is intended for rendering HTML which has retrieved from an external source (e.g. WP's REST API),
 * and should be used in conjunction with sanitisation measures implemented on the server side.
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * 1. Import this function at the top of any React component file that needs to render HTML from an external source:
 *
 * import {sanitiseHtml} from "../helpers/sanitiseHtml";
 *
 * 2. Add this code within the component - the browser will render the HTML contained in the 'originalHtml' variable:
 *
 * <div dangerouslySetInnerHTML={sanitiseHtml(originalHtml)} />
 *
 * ----------
 * - Notes: -
 * ----------
 *
 * This function currently uses the 'DOMPurify' JS library to perform the HTML sanitisation - see:
 * https://github.com/cure53/DOMPurify
 *
 * See https://github.com/cure53/DOMPurify/wiki/Security-Goals-&-Threat-Model for more details about how DOMPurify
 * is designed to work, including any associated limitations.
 *
 * See also https://dev.to/jam3/how-to-prevent-xss-attacks-when-using-dangerouslysetinnerhtml-in-react-1464
 * for info on using the DOMPurify library with React.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 *
 * @param {string} rawHtml
 *      The raw HTML from an external source which needs to be sanitised
 *
 * @returns {{__html: string}}
 *      The sanitised HTML, converted into a format designed to work with React's 'dangerouslySetInnerHTML' attribute
 */
export function sanitiseHtml(rawHtml) {
    return {
        __html: sanitise(rawHtml)
    };
}

/**
 * This function sanitises the specified HTML, so that it can be rendered more safely by the browser, using React's
 * 'dangerouslySetInnerHTML' attribute (see https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml) -
 * helping to protect against XSS attacks. Any custom 'shortcodes' within the content will automatically be replaced
 * with the correct alternative content.
 *
 * The function is intended for rendering HTML which has retrieved from an external source (e.g. WP's REST API),
 * and should be used in conjunction with sanitisation measures implemented on the server side. The shortcode
 * handling mechanism includes support for certain 'whitelisted' content (e.g. embedded iframes that load content
 * from predefined trusted third parties) which would otherwise be stripped out by the sanitisation mechanism.
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * 1. Import this function at the top of any React component file that needs to render HTML from an external source:
 *
 * import { sanitiseHtmlWithShortcodes } from "../helpers/sanitiseHtml";
 *
 * 2. Add this code within the component - the browser will render the HTML contained in the 'originalHtml' variable,
 * using the passed in app state to ensure that any shortcodes will be replaced with the correct content:
 *
 * <div dangerouslySetInnerHTML={sanitiseHtml(originalHtml, state)} />
 *
 * ----------
 * - Notes: -
 * ----------
 *
 * This function currently uses the 'DOMPurify' JS library to perform the HTML sanitisation - see:
 * https://github.com/cure53/DOMPurify
 *
 * See https://github.com/cure53/DOMPurify/wiki/Security-Goals-&-Threat-Model for more details about how DOMPurify
 * is designed to work, including any associated limitations.
 *
 * See also https://dev.to/jam3/how-to-prevent-xss-attacks-when-using-dangerouslysetinnerhtml-in-react-1464
 * for info on using the DOMPurify library with React.
 *
 * The shortcode handling mechanism does mean that some unsanitised HTML content could potentially be rendered within
 * the user's browser. Thus support for custom shortcodes should only be added when there is a high level of confidence
 * that the relevant content is safe, and is unlikely to be abused by malicious users to inject dangerous content.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 *
 * @param {string} rawHtml
 *      The raw HTML from an external source which needs to be sanitised
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 *
 * @returns {{__html: string}}
 *      The sanitised HTML, with any custom shortcodes processed, converted into a format designed to work with
 *      React's 'dangerouslySetInnerHTML' attribute
 */
export function sanitiseHtmlWithShortcodes(rawHtml, state) {
    // Handle any standard content shortcodes supported by this app
    const content = handleContentShortcodes(rawHtml, state);
    
    // Sanitise the HTML content
    const sanitisedContent = sanitise(content);
    
    /* Handle any additional shortcodes where the resulting content would otherwise be stripped by the sanitiser;
       then convert into a format that will work in a React component */
    return {
        __html: handleExtraContentShortcodes(sanitisedContent)
    };
}

/**
 * This function sanitises the specified HTML, so that it can subsequently be rendered more safely by the browser -
 * helping to protect against XSS attacks. Please instead use the sanitiseHtml() function contained within this file
 * if you need to use the resulting HTML directly within a React component.
 *
 * The function is intended for sanitising HTML which has retrieved from an external source (e.g. WP's REST API),
 * and should be used in conjunction with sanitisation measures implemented on the server side.
 *
 * ------------------
 * - Usage Example: -
 * ------------------
 *
 * import {sanitise} from "../helpers/sanitiseHtml";
 *
 * const sanitisedHTML = sanitise(originalHtml);
 *
 * ----------
 * - Notes: -
 * ----------
 *
 * This function currently uses the 'DOMPurify' JS library to perform the HTML sanitisation - see:
 * https://github.com/cure53/DOMPurify. It is intended to be used as a wrapper function, to allow the sanitisation
 * library and/or mechanism to be updated more easily if required in the future, without the need to update other
 * app components that utilise this functionality.
 *
 * See https://github.com/cure53/DOMPurify/wiki/Security-Goals-&-Threat-Model for more details about how DOMPurify
 * is designed to work, including any associated limitations.
 *
 * Usage Example:
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 *
 * @param {string} rawContent
 *      The raw HTML content from an external source which needs to be sanitised
 * @returns {string}
 *      The resulting sanitised HTML
 */
export function sanitise(rawContent) {
    return dompurify.sanitize(rawContent);
}