import { changeCurrentPage } from '../data/page/page.actions';
import { sanitise } from './sanitiseHtml';

/**
 * This function updates the current app state as soon as a page navigation button/link is clicked upon,
 * allowing certain known data about the new page to be updated 'instantly', thus providing a more seamless
 * user experience.
 *
 * The 'CHANGE_CURRENT_PAGE' action is dispatched with the relevant new page data if we are navigating to
 * a different page; nothing happens if we are already on the page being navigated to.
 *
 * [Note that this function does not directly handle the transition to the new page; that functionality
 * is provided separately by the Ionic & React Router functionality.]
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * 1. Import this function at the top of any React component file that includes links/buttons pointing to a new page:
 *
 * import { changePage } from '../helpers/changePage';
 *
 * 2. Add code similar to this in the component (the precise syntax will vary depending on the type of link/button,
 * but it should include an onClick() prop calling this function, as well as a prop that handles the page navigation):
 *
 * <Link to={item.link} onClick={() => changePage(item, state, dispatch)}>Page</Link>
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 *
 * @param {{link: string, template: string, title: string, section_title: string, banner_image: string}} newPageData
 *      Object containing data about the new page being navigated to. It may include data such as the new page link
 *      path, template, title and banner image - the app state will be updated to store this new data if present.
 *      All of these data properties are optional however. Any other properties that cannot be used to directly
 *      update the app page state will be ignored.
 * @param {Object} state
 *      Object containing the current overall app state. This can be retrieved from the AppContext.
 * @param {function} dispatch
 *       Dispatch function to use to update the global application state when the current page changes;
 *       this can be retrieved from the AppContext.
 */
export function changePage(newPageData, state, dispatch) {
    // Don't do anything if the clicked link/button points to the page we are already visiting
    if(newPageData.hasOwnProperty('link') && state.page.path === newPageData.link) {
        return;
    }
    
    /* Assemble new payload data to update the page state, based on the available new page data properties
       (use empty strings if any of these properties are not present in the new page data) */
    const newPagePayload = {
        template: newPageData.hasOwnProperty('template') ? newPageData.template : '',
        title: newPageData.hasOwnProperty('title') ? newPageData.title : '',
        section_title: newPageData.hasOwnProperty('section_title') ? newPageData.section_title : '',
        banner_image: newPageData.hasOwnProperty('banner_image') ? newPageData.banner_image : '',
    }
    
    // Dispatch the action to update the page state, so that it contains the new data
    dispatch(changeCurrentPage(newPagePayload));
    
    // Update page title
    if(state.config.data.title && newPageData.title) {
        // Use this method to ensure HTML entities are correctly decoded, whilst minimising security risks
        const pageTitle = state.config.data.title + ' - ' + newPageData.title;
        document.querySelector('title').innerHTML = sanitise(pageTitle);
    }
}