/**
 * This function can log any actions which update the app's state to the browser console, as well as full details
 * of the previous and next app state. This makes it easier to keep track of what is going on in the app,
 * making it simpler to debug any unexpected issues. The reducer(s) passed to this function are executed and
 * returned, so that the app state can be updated.
 *
 * Logging will only be enabled if the 'REACT_APP_ENABLE_STATE_LOGGING' environment variable is set to a value of 1.
 * This environment variable is defined in the '.env.development' or '.env.production' file in the app's root folder.
 * See: https://create-react-app.dev/docs/adding-custom-environment-variables/
 *
 * This function forms part of the simple Redux-like state management pattern for React which is implemented for
 * this app using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on the code in the 'Ionic Music Player React Demo' app
 * (see https://stackblitz.com/edit/ionic-react-demo?file=State.jsx).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export const stateLogger = (reducer) => {
    return (state, action) => {
        if(process.env.REACT_APP_ENABLE_STATE_LOGGING && process.env.REACT_APP_ENABLE_STATE_LOGGING === "1") {
            console.log("%cAction:", "color: #00A7F7; font-weight: 700;", action);
            console.log("%cPrevious State:", "color: #9E9E9E; font-weight: 700;", state);
            console.log("%cNext State:", "color: #47B04B; font-weight: 700;", reducer(state, action));
            console.log("%cTime:", "color: #071C8B; font-style: italic;", new Date().getTime() + 'ms, ' +  new Date());
        }
        
        return reducer(state, action);
    };
};