/**
 * @todo review & document
 */

import { userLoggedOut } from './user.actions';
import { setCurrentPagePath } from '../page/page.actions';
import { removeCookie } from '../general/cookieStorage';

export const logoutUser = async (dispatch) => {
    
    try {
        removeCookie('wellonlinepwa_user');
    
        dispatch(userLoggedOut());
    
        // Set page path to empty string, so page data will be re-retrieved if user logs in again
        dispatch(setCurrentPagePath(''));
    } catch(error) {
        // Log any storage errors to browser console
        console.error(error);
    }
};