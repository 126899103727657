/**
 * @todo review & document
 */

import React from 'react';
import { IonGrid, IonImg, isPlatform } from '@ionic/react';

import './TopBanner.css';

import { sanitiseHtml } from '../helpers/sanitiseHtml';

export const TopBanner = ({
    image,
    title,
    link,
    useH1
}) => {
    
    const onBannerClick = () => {
        // Don't navigate anywhere if a banner link was not specified
        if( ! link ) {
            return;
        }
    
        // Update window.location, because we don't have enough data about the link to transition more gracefully
        window.location = link;
    }
    
    return (
        <div className={link ? 'top-banner has-link' : 'top-banner'} onClick={onBannerClick}>
            <div className="top-banner-img-container">
                <IonImg src={image} className="top-banner-img" alt="Top Banner" />
    
                <IonGrid fixed>
                    <div className="top-banner-overlay">
                        <svg
                            className="top-banner-overlay-bg"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="423.95"
                            height="364.982"
                            viewBox="0 0 423.95 364.982"
                        >
                            <path
                                className="banner-overlay-1"
                                d="M167.934,58.719C245.3,13.394,327,73.2,327,163.5S266.237,286.124,175.939,286.124-14.145,259.553,26.491,170.467,90.57,104.043,167.934,58.719Z"
                                transform="translate(42.432 26.192)"
                            />
                            <path
                                className="banner-overlay-2"
                                d="M1063.668,361.226s-20.243,7.591-39.429-17.585c0,0-26.362-20.371-50.4,28.972-9.514,19.533-7.334,38.668-1.226,54.66,9.707,25.413,30.374,44.989,55.78,54.715,37.877,14.5,103.64,26.8,129.021-47.646,36.574-107.277-93.744-73.116-93.744-73.116"
                                transform="translate(480.737 1339.264) rotate(-120)"
                            />
                            <path
                                className="banner-overlay-3"
                                d="M1120.525,374.067s-32.194,12.073-62.707-27.967c0,0-41.925-32.4-80.156,46.076-15.131,31.064-11.664,61.5-1.95,86.929,15.437,40.416,48.306,71.548,88.71,87.016,60.238,23.061,164.826,42.62,205.19-75.774,58.165-170.609-149.087-116.281-149.087-116.281"
                                transform="matrix(0.875, 0.485, -0.485, 0.875, -563.172, -765.896)"
                            />
                            <path
                                className={isPlatform('ios') ? 'banner-overlay-4 no-gradient' : 'banner-overlay-4'}
                                d="M1107.174,371.052s-29.388,11.021-57.241-25.529c0,0-38.271-29.574-73.169,42.06-13.812,28.356-10.648,56.135-1.78,79.352,14.092,36.893,44.1,65.311,80.977,79.431,54.987,21.051,150.458,38.9,187.3-69.169,53.1-155.737-136.091-106.145-136.091-106.145"
                                transform="translate(-877.929 -254.282)"
                            />
                        </svg>
                        {useH1 !== false ? (
                            <h1
                                className="top-banner-overlay-text"
                                dangerouslySetInnerHTML={sanitiseHtml(title)}
                            />
                        ) : (
                            <div
                                className="top-banner-overlay-text"
                                dangerouslySetInnerHTML={sanitiseHtml(title)}
                            />
                        )}
                    </div>
                </IonGrid>
            </div>
            
            <div className="top-banner-mobile-text" dangerouslySetInnerHTML={sanitiseHtml(title)} />
        </div>
    );
    
};