/**
 * This function retrieves details of the menu items associated with the specified menu ID. This can be used
 * to retrieve the menu items for the correct menu, based on the applicable menu location.
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * 1. Import this function at the top of any React component file that needs to display a menu:
 *
 * import { getMenuItems } from '../helpers/getMenu';
 *
 * 2. Add code similar to this within the component (this code will retrieve the menu items for the 'header' menu):
 *
 * const menuItems = getMenuItems(state.config.data.menus, state.config.data.menu_locations.header);
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 *
 * @param {array} menus
 *      All the menus that are currently available in the app. This could be retrieved from the app state.
 * @param {number} menuId
 *      The ID of the menu to retrieve items for. This could be retrieved from the app state, based on the ID defined
 *      for a specified menu location. The items will be retrieved from the menus data passed into this function.
 *
 * @returns {array}
 *      The items in the relevant menu. This will be an empty array if the items cannot be retrieved for this menu.
 */
export function getMenuItems(menus, menuId) {
    // Loop through each menu until a menu with the specified ID is located
    for(let i = 0; i < menus.length; i++) {
        let curMenu = menus[i];
        if(curMenu.id === menuId) {
            // Get the items in this menu
            return curMenu.items;
        }
    }
    
    // The relevant menu cannot be located based on the supplied ID, so just return empty array
    return [];
}