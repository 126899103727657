/**
 * This component renders a search bar, which is styled to reflect the type of device being used to access the PWA.
 * The search bar is expected to be displayed within the header. It includes the following special functionality:
 *
 * - On mobile devices, it is automatically shown or hidden via CSS styling (incorporating a transition effect),
 * depending on the current search bar visibility setting stored in the app state.
 * - After the user has entered some new search text, the entered search text is stored in the app state, and the
 * user is automatically taken to the 'search' page. A 'debounce' effect is used to ensure this only occurs after
 * the user has finished (or has briefly paused) typing.
 * - A URL-encoded version of the entered search text is included in a query string within the search page URL,
 * to allow this data to be preserved if the user subsequently reloads the search results page.
 * - The text displayed within the search bar is retrieved from the current app state.
 * - When the user is automatically taken to the search page, the search bar remains focused, so they can continue
 * typing if required.
 * - If the user is not currently on the search page but has previously entered some search text, they are
 * automatically taken back to this search page if they click/tap on the search bar, so they can view the search
 * results again, and/or amend their search query to see new results.
 *
 * The search bar is rendered using the IonSearchbar component (see https://ionicframework.com/docs/api/searchbar),
 * and is surrounded by a IonToolbar component (see https://ionicframework.com/docs/api/toolbar), so must only
 * be used in locations where these toolbars are allowed.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React, { useContext, useRef } from 'react';
import {
    IonGrid,
    IonSearchbar,
    IonToolbar,
    useIonViewDidEnter
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router';

/* Get app context and any associated action(s), so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';
import { setSearchQuery } from '../data/search/search.actions';

/* Get component stylesheet(s) */
import './SearchBar.css';

export const SearchBar = () => {
    // Use app context to retrieve and update the current app search state as required
    const {state, dispatch} = useContext(AppContext);
    
    // Detect if we are currently on the 'Search' page
    const { pathname } = useLocation();
    const onSearchPage = pathname.substring(0, 7) === '/search';
    
    // Ensure the search bar stays focused after we have navigated to the 'Search' page
    const searchBarRef = useRef(null);
    useIonViewDidEnter(() => {
        if (onSearchPage &&
            searchBarRef.current &&
            typeof searchBarRef.current.setFocus !== 'undefined'
        ) {
            searchBarRef.current.setFocus();
        }
    });
    
    /* Handle changes to the text in the search bar (note the `debounce` property on the search bar means there may
       be a brief pause before this function gets executed, to allow the user to finish typing)
     */
    const history = useHistory();
    function onSearchBarTextChange(e) {
        const searchText = e.detail.value;
        
        // Don't perform any actions unless the search text has changed since it was last updated in the app state
        if(state.search.query === searchText) {
            return;
        }
        
        // Update the app state to store the new search text
        dispatch(setSearchQuery(searchText));
        
        // Update the browser URL, to point to the search page, and to include the new (URL-encoded) search text
        history.push('/search?query=' + encodeURIComponent(searchText));
    }
    
    // This runs when the search bar is focused; it returns the user back to the search page if required
    function onSearchBarFocus(e) {
        // Take user back to search page if they are not already on it, and they previously entered some search text
        if( ! onSearchPage && state.search.query.length > 0) {
            history.push('/search?query=' + encodeURIComponent(state.search.query));
        }
    }
    
    return (
        <IonToolbar className={state.search.showSearchBar ? 'site-searchbar' : 'site-searchbar hide-searchbar' }>
            <IonGrid fixed>
                <IonSearchbar
                    value={state.search.query}
                    onIonChange={onSearchBarTextChange}
                    onIonFocus={onSearchBarFocus}
                    debounce={750}
                    animated={true}
                    placeholder={state.config.data.text.search_title}
                    ref={searchBarRef}
                />
            </IonGrid>
        </IonToolbar>
    );
    
};