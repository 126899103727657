/**
 * @todo review & document
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonGrid, IonLoading } from '@ionic/react';

import { AppContext } from '../data/AppContext';

/* Get the app components that are used by this page content template */
import { SkeletonTextParagraphs } from '../components/SkeletonTextParagraphs';

export const ContentIsLoadingTemplate = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
  
    return (
        <IonGrid fixed>
            <IonLoading isOpen={true} message={state.config.data.text.page_loading_message} />
            <SkeletonTextParagraphs number="3" />
        </IonGrid>
    );
};
