/**
 * This file contains JS functions to set up the action objects which describe what happens when the app state
 * relating to the current app configuration is changed, e.g. as a result of retrieving config data from the
 * WP REST API (see the fetchAppConfig() function). Each action object specifies the action type and the associated
 * data which will be used to update the state via the associated reducer (see the config.reducer.js file).
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on a simplified version of the actions in the 'Ionic Conference App' template (see e.g.
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/user/user.actions.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export const initConfigFetch = () => (
    {
        type: 'CONFIG_FETCH_INIT'
    }
);

export const setConfigFetchSuccess = (payload) => (
    {
        type: 'CONFIG_FETCH_SUCCESS',
        payload: payload
    }
);

export const setConfigFetchError = () => (
    {
        type: 'CONFIG_FETCH_ERROR'
    }
);

export const setLiveChatEnabled = () => (
    {
        type: 'CONFIG_LIVE_CHAT_ENABLED'
    }
);

export const setLiveChatDisabled = () => (
    {
        type: 'CONFIG_LIVE_CHAT_DISABLED'
    }
);