/**
 * This component renders a dummy 'topic card' containing skeleton content - including areas for a title,
 * brief excerpt, button, and optional full-width image. A grid column contains the card. It is intended
 * for display in situations where the real topic cards are still being loaded. This component is based upon
 * the similar `TopicCard` component, which is designed to display real content instead.
 *
 * Cards are rendered using the IonCard component (see https://ionicframework.com/docs/api/card), and the
 * IonCol component surrounds each card. This component should therefore be used in conjunction with the
 * IonGrid and IonRow components (see https://ionicframework.com/docs/api/grid and
 * https://ionicframework.com/docs/layout/grid).
 *
 * See the code comments below for details of the 'prop(s)' that must be passed to this component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonSkeletonText
} from '@ionic/react';

/* Get app context and any associated action(s), so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get component stylesheet(s) */
import './TopicCard.css';

/**
 * The following 'prop(s)' must be passed to this component:
 *
 * @param {boolean} hasImage
 *      Is the actual topic card expected to include an image? If so, an additional skeleton block area is displayed
 *      at the top of the card, to simulate this image.
 */
export const TopicCardLoading = (
    {
        hasImage,
        sizeSm,
        sizeMd
    }
) => {
    
    // Use app context to retrieve the current app state
    const { state } = useContext(AppContext);
    
    return (
        <IonCol sizeXs={12} sizeSm={sizeSm} sizeMd={sizeMd}>
            <IonCard className="ion-text-center topic-card">
                {hasImage &&
                    <IonSkeletonText animated style={{ height: '180px', marginTop: '0px' }} />
                }
                <IonCardHeader>
                    <IonCardTitle className="ion-text-center">
                        <IonSkeletonText animated style={{ width: '75%', height: '20px', display: 'inline-block' }} />
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <p><IonSkeletonText animated /></p>
                    <p><IonSkeletonText animated style={{ width: '80%' }} /></p>
                    <IonButton>{state.config.data.text.read_more}</IonButton>
                </IonCardContent>
            </IonCard>
        </IonCol>
    );
    
};