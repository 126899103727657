/**
 * @todo review & document
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonGrid } from '@ionic/react';

/* Get app context, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get the app components that are used by this page content template */
import { BackgroundShape } from '../components/BackgroundShape';
import { TopContent } from '../components/TopContent';
import { HomeTopCTAs } from '../components/HomeTopCTAs';
import { HomeContactDetails } from '../components/HomeContactDetails';
import { HomeBottomContent } from '../components/HomeBottomContent';
import FeaturedTopics from '../components/FeaturedTopics';

export const HomeTemplate = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    const {page} = state;
  
    return (
        <div className='content-container'>
            
            <BackgroundShape position="left" hideOnSmallScreens={true} />
            <BackgroundShape position="right" />
            
            <IonGrid fixed>
                <TopContent />
                
                <HomeTopCTAs />
    
                <HomeContactDetails />
    
                <HomeBottomContent />
            </IonGrid>
    
            <FeaturedTopics
                heading={state.config.data.text.featured_topics_title}
                topics={page.data.featured_topics}
                isLoading={page.isLoading}
            />
        </div>
    );
};
