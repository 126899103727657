/**
 * This function combines multiple reducers which can be used to update the app state.
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on a simplified version of the code in the 'Ionic Conference App' template (see
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/combineReducers.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export function combineReducers(reducers) {
    return (state, action) => {
        const newState = {};
        const keys = Object.keys(reducers);
        keys.forEach(key => {
            const result = reducers[key](state[key], action);
            newState[key] = result || state[key];
        });
        return newState;
    };
}