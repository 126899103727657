/**
 * This file contains functionality to extract raw data from a JS object containing keys and values, so that the
 * data can safely be used throughout the app as required. Before using any raw data that was retrieved e.g. from
 * WP's REST API within the app, it is recommended to first pass it through this function, for the following
 * reasons:
 *
 * - Any data properties that do not exist within the raw data, or contain values which appear to be invalid
 *   (e.g. any NULL or undefined values) will be ignored, and instead a specified default value will be used for
 *   the relevant property, which is normally based on the initial value that gets stored for that property within
 *   the app state. This helps to protect against unexpected app crashes.
 * - If the raw data contains any additional data properties that are not expected, any irrelevant data will be
 *   stripped out, so the resulting data only contains the expected properties.
 * - Certain fields in the REST API responses contain an optional prefix to avoid conflicts with built-in or
 *   3rd party field names. This function allows the relevant field data to be extracted, and the prefix to be
 *   stripped out from the resulting data properties (where applicable), ensuring that the format of the
 *   extracted data is simpler.
 *
 * Note that this function is intended for extraction of raw data that closely matches the format of the
 * resulting data that will be used throughout the app. If any advanced data transformations need to be carried
 * out and/or other more sophisticated data extraction mechanisms must be used for certain data properties to
 * ensure that the data is usable within the app, the relevant property keys can be specified within the
 * skipProperties array, and the relevant data can then be extracted separately later via a different mechanism.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 *
 * @param {Object} rawData
 *      Raw data within a JS object containing keys and values. This may have been retrieved e.g. from WP's REST API.
 * @param {Object} defaultData
 *      The default values to use for any properties that cannot be successfully extracted from the raw data. The
 *      extracted data object will only contain the properties defined within this object; any additional properties
 *      in the raw data are discarded. The supplied object should contain all the properties that the app supports
 *      for this type of data, and is normally based upon the relevant keys & associated values that get stored in
 *      the initial app state.
 * @param {Array} skipProperties
 *      An array containing the keys of any properties within the default data that should be skipped, and thus not
 *      extracted from the raw data for now. This is normally used for properties that require their own dedicated
 *      (and more sophisticated) mechanism, which will extract the required data at a later stage.
 * @param {string} rawPropertyKeyPrefix
 *      This prefix can optionally be prepended to the key of a raw data property. Certain fields in the REST API
 *      responses contain an optional prefix to avoid conflicts with built-in or 3rd party field names, so this allows
 *      the relevant field data to be extracted, and the prefix to be stripped out from the resulting data properties.
 *      If an empty string (the default value) is used, this indicates the raw & formatted property key is the same.
 *
 * @returns {Object}
 *      Extracted data, which can be used throughout the app as required.
 */
export function extractStandardDataProperties(
    rawData,
    defaultData,
    skipProperties,
    rawPropertyKeyPrefix = ''
) {
    // Set up the default keys and values to use for the extracted data
    const extractedData = {...defaultData};
    
    // Loop through each applicable data property supported by the app, and extract corresponding raw data
    for (let propertyKey in extractedData) {
        // Get the raw data property key (inc optional prefix) corresponding to the equivalent extracted data property
        let rawPropertyKey = rawPropertyKeyPrefix + propertyKey;
    
        // Skip certain properties that have their own dedicated data extraction mechanism
        if(skipProperties.indexOf(propertyKey) !== -1) {
            continue;
        }
        
        // Skip any properties that cannot be found in the raw or extracted data
        if(
            ! extractedData.hasOwnProperty(propertyKey) ||
            ! rawData.hasOwnProperty(rawPropertyKey)
        ) {
            continue;
        }
        
        // Skip any properties that appear to contain invalid (i.e. non-'truthy') values
        if( ! rawData[rawPropertyKey]) {
            continue;
        }
        
        // Otherwise add the relevant raw data property value to the extracted data
        extractedData[propertyKey] = rawData[rawPropertyKey];
    }
    
    return extractedData;
}