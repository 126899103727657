/**
 * This file contains functionality to extract raw app configuration-related JSON data (which has already been
 * retrieved e.g. from WP's REST API), converting it into a simpler format which can then be used throughout the app.
 * If the raw data cannot be extracted/formatted correctly, an error will be thrown - this then needs to be handled
 * elsewhere in the app.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { configInitialState, homepageBannerInitialState, menuItemInitialState } from './config.init';

import { extractFormattedPageTemplateName, extractPagePath } from '../page/extractFormattedPageData';
import { extractStandardDataProperties } from '../general/extractStandardDataProperties';
import { getPagePathFromUrl } from '../../helpers/getPagePaths';

/**
 * This function converts the raw app configuration-related JSON data which has already been retrieved
 * (e.g. from WP's REST API) into a simpler format which can then be used throughout the app.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * @param {Object} rawConfig
 *      JS object containing raw app configuration data. This may have been retrieved e.g. from WP's REST API.
 * @returns {Object}
 *      Formatted app configuration data, which can be used throughout the app as required.
 *
 * @throws {Error}
 *      If the app configuration data cannot be formatted successfully
 */
export function extractFormattedConfig(rawConfig) {
    // Check if any app configuration data has been retrieved
    if( ! rawConfig || rawConfig.length === 0) {
        throw new Error('No app configuration data can be retrieved.');
    }
    
    /* Extract all of the standard configuration data properties supported by the app from the raw data,
       skipping those properties that require their own dedicated data extraction mechanism.  */
    const skipProperties = ['menus', 'menu_locations', 'colours', 'text', 'homepage_banners'];
    const formattedConfig = extractStandardDataProperties(
        rawConfig, configInitialState.data, skipProperties
    );
    
    // Attempt to extract more complex config data - e.g. menus (including associated menu items), colours, etc
    formattedConfig.menus = extractMenus(rawConfig);
    formattedConfig.menu_locations = extractMenuLocations(rawConfig, formattedConfig.menu_locations);
    formattedConfig.colours = extractFormattedColours(rawConfig);
    formattedConfig.text = extractFormattedTextStrings(rawConfig);
    formattedConfig.homepage_banners = extractHomepageBanners(rawConfig);
    
    return formattedConfig;
}

/**
 * This function converts raw retrieved JSON data containing details about the app menus (including associated menu
 * items) into a simpler format which can then be used throughout the app as required.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * @param {Object} rawConfig
 *      JS object containing raw app configuration data. This may have been retrieved e.g. from WP's REST API.
 * @returns {array}
 *      Formatted app menus data (including details of associated menu items), which can be used throughout the app.
 *
 * @throws {Error}
 *      If the app menus data cannot be formatted successfully
 */
function extractMenus(rawConfig) {
    // Don't extract any formatted menus data if no data relating to the menus exists
    if( ! rawConfig.hasOwnProperty('menus') || ! rawConfig.menus || rawConfig.menus.length === 0) {
        return [];
    }
    
    // Get the raw data relating to the app menus
    const rawMenus = rawConfig.menus; const formattedMenus = [];
    
    // Loop through each retrieved menu, and extract corresponding raw data
    for(let i = 0; i < rawMenus.length; i++) {
        // Get the raw data relating to the current retrieved menu
        let curRawMenu = rawMenus[i]; let curFormattedMenu = {};
    
        // Attempt to extract the menu ID. This property is required for all menus.
        if( ! curRawMenu.hasOwnProperty('id') || ! curRawMenu.id) {
            throw new Error('Unexpected menu data format: ID not specified.');
        }
        curFormattedMenu.id = curRawMenu.id;
    
        // Attempt to extract the menu items associated with this menu. This data is required for all menus.
        if( ! curRawMenu.hasOwnProperty('items')) {
            throw new Error('Unexpected menu data format: Items not specified.');
        }
        if(curRawMenu.items.length === 0) {
            continue; // Skip any menus with no menu items
        }
        curFormattedMenu.items = extractMenuItems(curRawMenu.items);
        
        formattedMenus.push(curFormattedMenu);
    }
    
    return formattedMenus;
}

/**
 * This function converts raw retrieved JSON data containing details about the items in an app menu into a simpler
 * format which can then be used throughout the app as required.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * @param {array} rawMenuItems
 *      Array containing raw data about all the items in a menu. This may have been retrieved e.g. from WP's REST API.
 * @returns {array}
 *      Formatted menu items data, which can be used throughout the app as required.
 *
 * @throws {Error}
 *      If the app menu items data cannot be formatted successfully
 */
function extractMenuItems(rawMenuItems) {
    const skipProperties = ['link', 'template'];
    
    // Loop through each retrieved menu item, and extract corresponding raw data
    const formattedMenuItems = [];
    for(let i = 0; i < rawMenuItems.length; i++) {
        // Get the raw data relating to the current retrieved menu item
        let curRawMenuItem = rawMenuItems[i];
    
        // Check that all the required menu item data properties exist in the raw data
        if( ! curRawMenuItem.hasOwnProperty('id') || ! curRawMenuItem.id) {
            throw new Error('Unexpected menu item data format: ID not specified.');
        }
        if( ! curRawMenuItem.hasOwnProperty('link') || ! curRawMenuItem.link) {
            throw new Error('Unexpected menu item data format: Link URL not specified.');
        }
        if( ! curRawMenuItem.hasOwnProperty('title')) {
            throw new Error('Unexpected menu item data format: Title not specified.');
        }
        
        /* Extract all of the standard menu item properties supported by the app from the raw data,
           skipping those properties that require their own dedicated data extraction mechanism.  */
        const curFormattedMenuItem = extractStandardDataProperties(
            curRawMenuItem, menuItemInitialState, skipProperties
        );
    
        // If this menu item is a custom link, extract the full link URL. Otherwise extract the relevant page path.
        if(curRawMenuItem.hasOwnProperty('type') && curRawMenuItem.type === 'custom') {
            curFormattedMenuItem.link = curRawMenuItem.link;
        } else {
            curFormattedMenuItem.link = extractPagePath(curRawMenuItem);
        }
    
        // Extract the template used to display the relevant page if available
        if(curRawMenuItem.hasOwnProperty('template')) {
            curFormattedMenuItem.template = extractFormattedPageTemplateName(curRawMenuItem.template);
        }
        
        formattedMenuItems.push(curFormattedMenuItem);
    }
    
    return formattedMenuItems;
}

/**
 * This function converts raw retrieved JSON data containing details about the app menu locations and associated menu
 * IDs into a simpler format which can then be used throughout the app as required.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * @param {Object} rawConfig
 *      JS object containing raw app configuration data. This may have been retrieved e.g. from WP's REST API.
 * @param {Object} initMenuLocations
 *      Object containing properties corresponding to the initial standard menu locations supported by the app.
 * @returns {Object}
 *      Formatted menu locations and corresponding menu IDs, which can be used throughout the app.
 *
 * @throws {Error}
 *      If the app menu locations data cannot be formatted successfully
 */
function extractMenuLocations(rawConfig, initMenuLocations) {
    // Just use the standard defined menu locations data if the raw data doesn't contain menu locations details
    if(
        ! rawConfig.hasOwnProperty('menu_locations') ||
        ! rawConfig.menu_locations ||
        rawConfig.menu_locations.length === 0
    ) {
        return initMenuLocations;
    }
    
    // Extract all of the standard menu locations supported by the app from the raw data
    return extractStandardDataProperties(
        rawConfig.menu_locations, initMenuLocations, []
    );
}

/**
 * This function converts the raw app colours JSON data which has already been retrieved
 * (e.g. from WP's REST API) into a simpler format which can then be used throughout the app.
 *
 * @param {Object} rawConfig
 *      JS object containing raw app configuration data. This may have been retrieved e.g. from WP's REST API.
 * @returns {Object}
 *      Formatted colours data, which can be used throughout the app as required.
 */
export function extractFormattedColours(rawConfig) {
    // Set up the default values to use for the formatted colours data
    const initialColours = {...configInitialState.data.colours};
    
    // Check if any colours data has been retrieved, use default colours if not
    if( ! rawConfig.hasOwnProperty('colours') ||  ! rawConfig.colours || rawConfig.colours.length === 0) {
        return initialColours;
    }
    
    // Extract all of the standard colours supported by the app from the raw data
    return extractStandardDataProperties(rawConfig.colours, initialColours, []);
}

/**
 * This function converts the raw configurable app text strings JSON data which has already been retrieved
 * (e.g. from WP's REST API) into a simpler format which can then be used throughout the app.
 *
 * @param {Object} rawConfig
 *      JS object containing raw app configuration data. This may have been retrieved e.g. from WP's REST API.
 * @returns {Object}
 *      Formatted configurable text strings data, which can be used throughout the app as required.
 */
export function extractFormattedTextStrings(rawConfig) {
    // Set up the default values to use for the formatted text strings data
    const initialTextStrings = {...configInitialState.data.text};
    
    // Check if any text strings data has been retrieved, use default text strings if not
    if( ! rawConfig.hasOwnProperty('text') ||  ! rawConfig.text || rawConfig.text.length === 0) {
        return initialTextStrings;
    }
    
    // Extract all of the standard text strings supported by the app from the raw data
    return extractStandardDataProperties(rawConfig.text, initialTextStrings, []);
}

/**
 * This function converts raw retrieved JSON data containing details about the homepage banners into a simpler
 * format which can then be used throughout the app as required.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * @param {Object} rawConfig
 *      JS object containing raw app configuration data. This may have been retrieved e.g. from WP's REST API.
 * @returns {array}
 *      Formatted homepage banners data, which can be used throughout the app as required.
 */
function extractHomepageBanners(rawConfig) {
    const skipProperties = ['page_link'];
    
    // Don't extract any formatted homepage banners data if no data relating to these banners exists
    if(
        ! rawConfig.hasOwnProperty('homepage_banners') ||
        ! rawConfig.homepage_banners ||
        rawConfig.homepage_banners.length === 0
    ) {
        return [];
    }
    
    // Loop through each retrieved homepage banner, and extract corresponding raw data
    const rawBanners = rawConfig.homepage_banners; const formattedBanners = [];
    for(let i = 0; i < rawBanners.length; i++) {
        // Get the raw data relating to the current retrieved homepage banner
        let curRawBanner = rawBanners[i];
        
        /* Extract all of the standard homepage banner properties supported by the app from the raw data,
           skipping those properties that require their own dedicated data extraction mechanism.  */
        const curFormattedBanner = extractStandardDataProperties(
            curRawBanner, homepageBannerInitialState, skipProperties
        );
        
        // If a page link has been specified for this banner, extract the page path from this link
        if(curRawBanner.hasOwnProperty('page_link') && curRawBanner.page_link) {
            curFormattedBanner.link = getPagePathFromUrl(curRawBanner.page_link);
        }
        
        formattedBanners.push(curFormattedBanner);
    }
    
    return formattedBanners;
}