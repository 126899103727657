/**
 * This file implements functionality to retrieve page data in JSON format from WP's REST API.
 *
 * Page-related information (including whether data is still being loaded, whether any errors were encountered,
 * and the latest retrieved page data) is stored within the overall application state (see 'data/state.js'),
 * and can therefore be retrieved from anywhere in the app where needed.
 *
 * If the page data is retrieved successfully, page visit stats are sent back to the WP REST API, using the
 * separate `sendStatsToApi()` function. This is implemented via a separate API request, to allow the main
 * page data response to be cached via service workers etc, without interfering with stats collection.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { initPageDataFetch, setPageDataFetchError, setPageDataFetchSuccess } from './page.actions';
import { extractFormattedPageData } from './extractFormattedPageData';
import { handleUserAuthenticationErrors } from '../../helpers/handleUserAuthenticationErrors';
import { extractPageSlug } from '../../helpers/getPagePaths';
import { sendStatsToApi } from '../general/sendStatsToApi';
import * as ReactGA from 'react-ga';
import { sanitise } from '../../helpers/sanitiseHtml';

/**
 * Asynchronous function to retrieve page data in JSON format from the WP REST API.
 *
 * @param {string} pagePath
 *      Full page path to retrieve page data for (e.g. '/work-life/bullying/')
 * @param {string} userToken
 *      JSON Web Token (JWT), to ensure user is allowed to access the page data
 * @param {Object} config
 *      Object containing the current app configuration data
 * @param {function} dispatch
 *      Dispatch function to use to update global application state when data retrieval status changes
 *
 * @returns {Promise<void>}
 */
export const fetchPageDataFromApi = async (pagePath, userToken, config, dispatch) => {
    // Set URL path for REST API request
    const apiUrlPath = '/wp-json/wp/v2/pages/?slug=';
    
    // Extract page 'slug' from specified page path, so correct data can be retrieved from API
    const pageSlug = extractPageSlug(pagePath, config);
    if(pageSlug === '') {
        return; // Don't fetch any data if page slug is empty
    }
    
    // Update app state to indicate that page data fetching is in progress
    dispatch(initPageDataFetch());
    
    try {
        // Wait for JSON data response to be retrieved from URL
        const response = await fetch(apiUrlPath + pageSlug, {
            headers: {
                authorization: 'Bearer ' + userToken
            }
        });
        const result = await response.json();
        
        // Handle any errors relating to the authentication of the current user
        await handleUserAuthenticationErrors(result, dispatch);
        
        // Convert retrieved data into expected format
        const pageData = extractFormattedPageData(result, pagePath);
        
        // Update app state to keep track of the successfully retrieved data
        dispatch(setPageDataFetchSuccess(pageData));
        
        // Send page visit stats to the WP REST API
        sendPageVisitStatsToApi(pageData.id, userToken, dispatch);
        
        // Send page visit stats to Google Analytics
        if(config.ga_code) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        
        // Update page title
        if(config.title && pageData.title) {
            // Use this method to ensure HTML entities are correctly decoded, whilst minimising security risks
            const pageTitle = config.title + ' - ' + pageData.title;
            document.querySelector('title').innerHTML = sanitise(pageTitle);
        }
    } catch (error) {
        // Update app state to indicate that errors occurred when retrieving data
        dispatch(setPageDataFetchError());
        // Log any data retrieval errors to browser console
        console.error(error);
    }
};

/**
 * If the page data was retrieved successfully, send page visit stats back to the WP REST API, using the
 * separate `sendStatsToApi()` function. This is implemented via a separate API request, to allow the main page
 * data response to be cached via service workers etc, without interfering with stats collection.
 *
 * @param {number} pageId
 *     The ID of the page which has been visited
 * @param {string} userToken
 *     JSON Web Token (JWT), so the server can identify which user to collect statistics for
 * @param {function} dispatch
 *     Dispatch function to use to update global application state if an authentication error response was returned
 *
 * @returns {Promise<void>}
 */
const sendPageVisitStatsToApi = (pageId, userToken, dispatch) => {
    const pageVisitData = {
        page_id: pageId
    }
    
    sendStatsToApi(pageVisitData, 'page', userToken, dispatch);
};