/**
 * This component renders the main content that appears at the top of various pages. On the left and right hand side
 * of this content, illustrated stick figures are displayed; these are automatically moved below the content and
 * appear centred horizontally at smaller screen sizes. Skeleton text is shown while the text content is still
 * loading, whereas the illustrated stick figures are always displayed. Any 'shortcodes' within the content will
 * automatically be replaced with the appropriate HTML content.
 *
 * The IonGrid component (see https://ionicframework.com/docs/api/grid & https://ionicframework.com/docs/layout/grid)
 * is used to control the sizing and positioning of the text content and illustrated stick figure images, utilising
 * built-in IonGrid features as well as some custom styling to adjust the columns at different screen sizes.
 *
 * The page content displayed by this component is retrieved from the app state.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonCol, IonGrid, IonImg, IonRow, IonSkeletonText } from '@ionic/react';

/* Get app context and any associated action(s), so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get required custom app components */
import { SkeletonTextParagraphs } from './SkeletonTextParagraphs';

/* Import app helper function(s) */
import { sanitiseHtmlWithShortcodes } from '../helpers/sanitiseHtml';

/* Import image file asset(s) used by this component */
import stickFigureImg1 from '../images/stick-figure-1.png';
import stickFiguresImg2 from '../images/stick-figures-2.png';

/* Get component stylesheet(s) */
import './TopContent.css';

export const TopContent = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    
    return (
        <IonGrid className="top-content">
            <IonRow className="ion-align-items-center">
                
                <IonCol sizeXs="12" sizeMd="10" pushMd="1" className="ion-padding-horizontal">
                    { ! state.page.isLoading ? (
                        <div className="ion-text-center">
                            <div dangerouslySetInnerHTML={sanitiseHtmlWithShortcodes(
                                state.page.data.content,
                                state
                            )} />
                        </div>
                    ) : (
                          <>
                              <p className="ion-text-center">
                                  <IonSkeletonText
                                      animated
                                      style={{ width: '50%', height: '30px', display: 'inline-block' }}
                                  />
                              </p>
                              <SkeletonTextParagraphs number="1" />
                          </>
                      )}
                </IonCol>
                
                <IonCol sizeXs="4" sizeMd="0" className="pad-mobile" />
                
                <IonCol sizeXs="2" sizeMd="1" pullMd="10">
                    <IonImg src={stickFigureImg1} alt="Stick figure" className="pad-figure" />
                </IonCol>
                
                <IonCol sizeXs="2" sizeMd="1">
                    <IonImg src={stickFiguresImg2} alt="Stick figures" />
                </IonCol>
                
                <IonCol sizeXs="4" sizeMd="0" className="pad-mobile" />
                
            </IonRow>
        </IonGrid>
    );
};