/**
 * This component renders a 'Live Chat' icon on the bottom right of the screen. When this is icon tapped upon,
 * a new window/tab is opened, pointing to the URL of an external Live Chat provider. The icon is only made visible
 * if Live Chat is currently enabled upon the current site. If this component is no longer being rendered, the live
 * chat elements are removed from the screen automatically.
 *
 * This component also includes a `useEffect()` React hook, which automatically updates the app state to enable or
 * disable the Live Chat functionality throughout the app, whenever certain conditions, events or time intervals occur:
 *
 * - The app config data stored in the overall app state has changed, e.g. after it has been retrieved from the server.
 * - The current user login status has changed.
 * - The user has now gone offline or online.
 * - Once every minute (this allows the Live Chat to be enabled or disabled based on the current date or time).
 *
 * The use of event listeners and time intervals within the `useEffect()` hook is inspired by the approaches described
 * at https://www.pluralsight.com/guides/event-listeners-in-react-components and https://stackoverflow.com/a/65049865.
 * These event listeners and time intervals are automatically removed if this Live Chat component gets unmounted.
 *
 * If any of the above conditions, events or time intervals occur, the `updateLiveChatStatus()` function within the
 * `data/config/updateLiveChatStatus` file is called, which then performs a series of comprehensive checks to determine
 * whether the Live Chat should ultimately be enabled or disabled. This ensures that the Live Chat will *only* be
 * displayed if none of the potential reasons for hiding it are currently applicable.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React, { useContext, useEffect } from 'react';

/* Get app context and associated actions, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Import app helper function(s) */
import { openLiveChat, logLiveChatStatus } from '../helpers/openLiveChat';

/* Get component stylesheet(s) */
import './LiveChat.css';
import { updateLiveChatStatus } from '../data/config/updateLiveChatStatus';

export const LiveChat = () => {
    
    // Use app context to retrieve & update the app state as required
    const { state, dispatch } = useContext(AppContext);
    
    // Enable or disable Live Chat when certain conditions, events or time intervals occur:
    useEffect(() => {
        // Wrapper function to handle any updates that are needed to the Live Chat status:
        const handleLiveChatStatusUpdates = () => {
            // Perform some more comprehensive checks and then update the Live Chat status accordingly.
            updateLiveChatStatus(state.config.data, state.user.loggedIn, dispatch);
        };
        
        // Ensure Live Chat gets enabled/disabled upon initial render, or if app config or user login status changes.
        handleLiveChatStatusUpdates();
    
        // Set up time intervals and event listeners to enable/disable the Live Chat.
        const updateStatusEachMinute = setInterval(handleLiveChatStatusUpdates, 60000);
        window.addEventListener('online', handleLiveChatStatusUpdates);
        window.addEventListener('offline', handleLiveChatStatusUpdates);
    
        // If this component is unmounted, remove the time intervals and event listeners added above.
        return () => {
            clearInterval(updateStatusEachMinute);
            window.removeEventListener('online', handleLiveChatStatusUpdates)
            window.removeEventListener('online', handleLiveChatStatusUpdates);
        };
        
    }, [state.config.data, state.user.loggedIn, dispatch]); // Re-run if app config or user login status changes.
    
    // Don't load the Live Chat overlay button if the live chat functionality is not currently available on this site.
    if(!state.config.liveChatEnabled) {
        logLiveChatStatus('Not displaying main Live Chat overlay button, because Live Chat is currently disabled.');
        return null;
    } else {
        logLiveChatStatus('Displaying main Live Chat overlay button.');
    }
    
    return (
        <div className="live-chat-overlay-button" onClick={() => openLiveChat(state)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4749.48 -5020 35.036 35.036" className="live-chat-overlay-button-icon">
                <defs>
                    <clipPath id="a">
                        <path d="M0-399.479h17.555v17.555H0z" transform="translate(0 399.479)" fill="none"></path>
                    </clipPath>
                </defs>
                <g clipPath="url(#a)" transform="translate(-4740.87 -5011)">
                    <path
                          d="M17.555 8.778A8.778 8.778 0 008.777 0 8.778 8.778 0 00-.001 8.778a8.745 8.745 0 002.26 5.879v1.442c0 .8.492 1.457 1.1 1.457h5.83a.843.843 0 00.183-.02 8.778 8.778 0 008.184-8.757"
                          fill="#fff"></path>
                    <path
                          d="M3.161 8.921A9.292 9.292 0 019.541.033 8.763 8.763 0 008.778 0 8.774 8.774 0 000 8.778 9.508 9.508 0 002.224 14.7c.005 0 0 .009 0 .01-.311.352-1.924 2.849.021 2.849h2.25c-1.23-.022 1.263-2.107.269-3.494a8.225 8.225 0 01-1.6-5.141"
                          fill="#eff4f9"></path>
                </g>
            </svg>
        </div>
    );
};