/**
 * @todo review & document
 */
import React, { useContext, useRef, useState } from 'react';
import {
    IonButton,
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonLoading,
    IonGrid,
    IonHeader,
    IonToolbar,
    IonText
} from '@ionic/react';

import { AppContext } from '../data/AppContext';
import { loginUser } from '../data/user/loginUser';

import { TopBanner } from '../components/TopBanner';

import { handleContentShortcodes } from '../helpers/handleContentShortcodes';
import { sanitiseHtml } from '../helpers/sanitiseHtml';

import topBannerPlaceholderImg from '../images/top-banner-default.jpg';

export const LoginPage = () => {
    const { state, dispatch } = useContext(AppContext);
    
    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    
    const formRef = useRef(null);
    
    const handleSubmit = async e => {
        e.preventDefault();
        await loginUser(username, password, state, dispatch);
    };
    
    const logo = state.config.data.logo;
    const topBannerImg = state.config.data.banner_image ? state.config.data.banner_image : topBannerPlaceholderImg;
    const loginPageText = handleContentShortcodes(state.config.data.text.login_page, state);
    
    return (
        <IonPage id="login-page">
            <IonHeader>
                <IonToolbar>
                    {logo && (
                        <p className="ion-text-center">
                            <img src={logo} alt="Well Online logo" />
                        </p>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent className="form">
                {topBannerImg && (
                    <TopBanner image={topBannerImg} title={state.config.data.text.login_title} />
                )}
                <IonGrid fixed>
                    
                    <IonLoading
                        isOpen={state.user.startedLogin}
                        message={state.config.data.text.login_loading_message}
                    />
                    
                    {loginPageText && (
                        <p className="ion-text-center" dangerouslySetInnerHTML={sanitiseHtml(loginPageText)} />
                    )}
                    
                    {state.user.loginErrors.length > 0 && (
                        <p className="error-message ion-text-center">
                            {state.user.loginErrors}
                        </p>
                    ) }
                    
                    <form onSubmit={handleSubmit} method="post" ref={formRef} action="">
                        <IonList>
                            <IonItem>
                                <IonLabel style={{fontSize: '20px'}} position="stacked">
                                    {state.config.data.text.username_field_label} <IonText color="danger">*</IonText>
                                </IonLabel>
                                <IonInput
                                    type="text"
                                    value={username}
                                    required={true}
                                    onInput={e => setUsername(e.currentTarget.value)}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel style={{fontSize: '20px'}} position="stacked">
                                    {state.config.data.text.password_field_label} <IonText color="danger">*</IonText>
                                </IonLabel>
                                <IonInput
                                    type="password"
                                    value={password}
                                    required={true}
                                    onInput={e => setPassword(e.currentTarget.value)}
                                />
                            </IonItem>
                        </IonList>
                        <p className="ion-padding-top">
                            <IonButton expand="block" type="submit">{state.config.data.text.login_title}</IonButton>
                        </p>
                    </form>
                    
                </IonGrid>
            </IonContent>
        </IonPage>
    );
};