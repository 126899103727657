/**
 * @todo review & document
 */

import React, { useContext } from 'react';

import topBannerPlaceholderImg from '../images/top-banner-default.jpg';

import { AppContext } from '../data/AppContext';
import { useLocation } from 'react-router-dom';
import { TopBannerSlider } from './TopBannerSlider';
import { TopBanner } from './TopBanner';

export const TopBanners = () => {
    
    const { state } = useContext(AppContext);
    const { data } = state.page;
    
    const location = useLocation();
    if(location.pathname === '/' && state.config.data.homepage_banners.length > 0) {
        return <TopBannerSlider banners={state.config.data.homepage_banners} />;
    }
    
    const topBannerImg = data.banner_image ? data.banner_image : topBannerPlaceholderImg;
    const topBannerTitle = ! state.page.isError ?
                                        data.section_title : state.config.data.text.page_load_error_title;
    
    return (
        <TopBanner image={topBannerImg} title={topBannerTitle} useH1={true} />
    );
    
};