/**
 * This component renders 'cards' to display based on the retrieved search results. Each search result card includes
 * a title, brief excerpt, full-width image (a placeholder image is used if an image is not explicitly specified)
 * and button; and the card links to another page within the app. The cards are displayed within a 2 column grid
 * (the cards stretch horizontally to fit the available space if there are less than 2 cards in a column), which is
 * horizontally centred within the page content.
 *
 * A heading is displayed above the results, containing details of the current search query. If the search results
 * are still loading, 2 cards containing 'skeleton' content will be displayed instead. If no search results could
 * be retrieved, a message is displayed inviting the user to try entering a different search query.
 *
 * Cards are rendered using the IonCard component (see https://ionicframework.com/docs/api/card), and the
 * IonGrid component is used to render the grid and to centre this horizontally within the page content (see
 * https://ionicframework.com/docs/api/grid and https://ionicframework.com/docs/layout/grid).
 *
 * See the code comments below for details of the 'props' that must be passed to this component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonGrid, IonRow } from '@ionic/react';

/* Get app context and any associated action(s), so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get required custom app components */
import { TopicCard } from './TopicCard';
import { TopicCardLoading } from './TopicCardLoading';

/* Get placeholder image asset(s) */
import searchResultPlaceholderImg from '../images/card-img-default.jpg';

/**
 *  The following 'props' must be passed to this component:
 *
 * @param {string} query
 *      The search query entered by the user. This will be displayed in the heading and in any warning messages.
 * @param {array} results
 *      Array of JSON objects containing the search results. Each search result includes the details to display in
 *      the relevant card - including title, excerpt, page link path, image (if applicable), and content template
 *      to use if navigating to a new page. For example:
 *
 *      [
 *          {
 *              'title': 'Bullying in the Workplace',
 *              'link': '/work-life/bullying/bullying-in-the-workplace/',
 *              'excerpt': 'Some excerpt text ...',
 *              'featured_image': 'https://www.example.com/image1.jpg',
 *              'template': 'page-topic'
 *           },
 *           {
 *              'title': 'A bully or a strong manager?',
 *              'link': '/work-life/bullying/a-bully-or-a-strong-manager/',
 *              'excerpt': 'Some other excerpt text ...',
 *              'featured_image': 'https://www.example.com/image2.jpg',
 *              'template': 'page-topic'
 *           }
 *       ]
 *
 * @param {boolean} isLoading
 *      Are the search results still loading? If so, 2 cards containing 'skeleton' content will be displayed instead
 */
export const SearchResults = (
    {
        query,
        results,
        isLoading
    }
) => {
    
    // Use app context to retrieve the current app state
    const {state} = useContext(AppContext);
    
    const totalNumCols = 2; const rows = [];
    
    // Assemble the search results to display if they are not still loading
    if( ! isLoading) {
        // Calculate the number of rows to render, based on the number of search results
        const totalNumRows = Math.ceil(results.length / totalNumCols);
    
        // Loop through each row and column, assembling the search result cards to render in each grid 'cell'
        let curResultNum = 0;
        for(let curRowNum = 0; curRowNum < totalNumRows; curRowNum++) {
            let cols = []; // Reset the content to render in the columns for the next row
            for(let curColNum = 0; curColNum < totalNumCols; curColNum++) {
                // Do not assemble any further cards if we have already assembled cards for all available search results
                if(curResultNum === results.length) {
                    break;
                }
            
                // Get the next available search result
                let result = results[curResultNum];
    
                // Get featured image, or 'default' image if search result appears to have no featured image
                const featuredImg = result.featured_image ? result.featured_image : searchResultPlaceholderImg;
            
                // Assemble the next result card and add it to the array of assembled columns within the current row.
                cols.push(
                    <TopicCard key={result.id} topic={result} image={featuredImg} sizeSm={6} sizeMd={6} />
                );
            
                // Proceed to the next available search result
                curResultNum++;
            }
        
            // Assemble the next row based on the assembled columns, and add this to the array of assembled rows.
            rows.push(
                <IonRow key={curRowNum}>
                    {cols}
                </IonRow>
            );
        }
    } else {
        // Otherwise, if content is still loading, assemble 4 result cards containing skeleton content
        let cols = [];
        for(let curResultNum = 0; curResultNum < totalNumCols; curResultNum++) {
            cols.push(
                <TopicCardLoading key={curResultNum} hasImage={true} sizeSm={6} sizeMd={6} />
            );
        }
        rows.push(
            <IonRow key="0">
                {cols}
            </IonRow>
        );
    }
    
    /* Render assembled search result rows in a grid, with a top heading containing the query.
       Display a warning message if no search results are available after loading has completed. */
    return (
        <IonGrid fixed>
            <h2 className="ion-text-center">
                {state.config.data.text.search_results_title.replace('[SEARCH_QUERY]', query)}
            </h2>
            
            {rows.length > 0 && (
                <IonGrid>
                    {rows}
                </IonGrid>
            )}
    
            { ! isLoading && results.length === 0 && (
                <p className="ion-text-center">
                    {state.config.data.text.no_search_results_message.replace(
                        '[SEARCH_QUERY]', query
                    )}
                </p>
            )}
            
        </IonGrid>
    );
    
};