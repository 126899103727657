/**
 * @todo review & document
 */

import React, { useContext } from 'react';
import {IonCol, IonGrid, IonIcon, IonImg, IonRow, IonSkeletonText} from '@ionic/react';
import { call, chatbubbles, mail } from 'ionicons/icons';

import './HomeContactDetails.css';

import contactPlaceholderImg from '../images/home-contact-default.jpg';
import { AppContext } from '../data/AppContext';
import { sanitiseHtmlWithShortcodes } from '../helpers/sanitiseHtml';
import { getContactEmailAddress, getContactPhoneNumber, getContactPhoneUrl } from '../helpers/getContactDetails';
import { openLiveChat } from '../helpers/openLiveChat';

export const HomeContactDetails = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    const {isLoading, data} = state.page;
    
    // Don't display this component unless it is enabled within the page settings
    if(data.home_display_contact_section !== 'yes' && ! isLoading) {
        return null;
    }
    
    // Get the contact details displayed in this component
    const phone = getContactPhoneNumber(state);
    const phoneUrl = getContactPhoneUrl(state);
    const email = getContactEmailAddress(state);
    
    return (
        <IonGrid className="home-contact-details">
            <IonRow className="ion-align-items-center">
                <IonCol size-xs="12" size-sm="6">
                    { ! isLoading ? (
                        <IonImg
                            src={data.featured_image ? data.featured_image : contactPlaceholderImg}
                            alt="Contact Us image"
                        />
                    ) : (
                        <IonSkeletonText animated style={{ height: '350px' }} />
                    )}
                </IonCol>
                <IonCol size-xs="12" size-sm="6" className="text-content">
                    { ! isLoading ? (
                        <>
                            <div dangerouslySetInnerHTML={sanitiseHtmlWithShortcodes(
                                data.home_contact_section_content,
                                state
                            )} />
                            
                            {state.config.data.display_phone !== 'no' &&
                             data.home_display_phone_number !== 'no' &&
                             phone && (
                                <p>
                                    <IonIcon icon={call} color="primary" />
                                    <strong><a href={phoneUrl}>{phone}</a></strong>
                                </p>
                            )}
                            {state.config.liveChatEnabled &&
                             data.home_display_live_chat !== 'no' && (
                                <p>
                                    <IonIcon icon={chatbubbles} color="primary" />
                                    <strong className="live-chat-link" onClick={() => openLiveChat(state)}>
                                        {state.config.data.text.live_chat}
                                    </strong>
                                </p>
                            )}
                            {state.config.data.display_email !== 'no' &&
                             data.home_display_message_link !== 'no' &&
                             email && (
                                <p>
                                    <IonIcon icon={mail} color="primary" />
                                    <strong><a href={'mailto:' + email}>{state.config.data.text.contact}</a></strong>
                                </p>
                            )}
                        </>
                    ) : (
                        <>
                            <IonSkeletonText animated style={{ width: '50%', height: '30px' }} />
                            <p><IonSkeletonText animated /></p>
                            <p>
                                <IonIcon icon={call} color="primary" />
                                <IonSkeletonText animated style={{ width: '25%', display: 'inline-block' }} />
                            </p>
                            <p>
                                <IonIcon icon={chatbubbles} color="primary" />
                                <IonSkeletonText animated style={{ width: '25%', display: 'inline-block' }} />
                            </p>
                            <p>
                                <IonIcon icon={mail} color="primary" />
                                <IonSkeletonText animated style={{ width: '25%', display: 'inline-block' }} />
                            </p>
                        </>
                    )}
                </IonCol>
            </IonRow>
        </IonGrid>
    );
    
};