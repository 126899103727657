/**
 * This file contains functionality to extract raw page JSON data (which has already been retrieved e.g. from WP's
 * REST API), converting it into a simpler format which can then be used throughout the app. If the raw data cannot
 * be extracted/formatted correctly, an error will be thrown - this then needs to be handled elsewhere in the app.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { pageInitialData, relatedPageInitialData } from './pageData.init';

import { getPagePathFromUrl } from '../../helpers/getPagePaths';
import { extractStandardDataProperties } from '../general/extractStandardDataProperties';

/**
 * This function extracts raw page JSON data (which has already been retrieved e.g. from WP's REST API),
 * converting it into a simpler format which can then be used throughout the app. If the raw data cannot
 * be extracted/formatted correctly, an error is thrown.
 *
 * Note that WP's REST API may retrieve multiple pages matching the current page slug. To ensure the correct
 * page data is extracted, the expected page path must be passed into this function. This is then matched
 * against the full path within the retrieved data for each page. An error is thrown if none of the retrieved
 * page paths match this expected path.
 *
 * @param {array} rawPageData
 *      Raw page data in JSON format. This may have been retrieved e.g. from WP's REST API.
 * @param {string} expectedPagePath
 *      Full path of expected page (e.g. '/work-life/bullying/'). This is used to ensure the correct page data
 *      can extracted if data for multiple pages was retrieved.
 * @returns {Object}
 *      Formatted page data which can be used throughout the app as required.
 *
 * @throws {Error}
 *      If the page data cannot be extracted/formatted successfully
 */
export const extractFormattedPageData = (rawPageData, expectedPagePath) => {
    // Check if data for any pages has been retrieved
    if( ! rawPageData || rawPageData.length === 0) {
        throw new Error('No page data can be retrieved. This page may not exist?');
    }
    
    // Loop through the retrieved data for each page, so data can be extracted for the correct page (if applicable)
    for(let i = 0; i < rawPageData.length; i++) {
        // Get the raw data relating to the current retrieved page
        let curPageData = rawPageData[i];
        
        // Attempt to extract the page path from the current page data
        const curPagePath = extractPagePath(curPageData);
        
        // Skip the data for this page if it does not match the expected page path
        if(curPagePath !== expectedPagePath) {
            continue;
        }
    
        // Data for a page with a matching path has been extracted. Return formatted data for this page.
        return formatCurrentPageData(curPageData);
    }
    
    // Throw an error only if none of the retrieved pages have a path matching the expected path.
    throw new Error('No pages matching the current page path can be retrieved.');
};

/**
 * This function converts the raw JSON data which has already been retrieved for the current page
 * (e.g. from WP's REST API) into a simpler format which can then be used throughout the app.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * @param {Object} rawPageData
 *      Raw data for the current page in JSON format. This may have been retrieved e.g. from WP's REST API.
 * @returns {Object}
 *      Formatted data for the current page, which can be used throughout the app as required.
 *
 * @throws {Error}
 *      If the current page data cannot be formatted successfully
 */
function formatCurrentPageData(rawPageData) {
    // Check that all the required page data properties exist in the raw data
    if( ! rawPageData.hasOwnProperty('id') || ! rawPageData.id) {
        throw new Error('Unexpected page data format: ID not specified.');
    }
    if(
        ! rawPageData.hasOwnProperty('title') ||
        ! rawPageData.title ||
        ! rawPageData.title.hasOwnProperty('rendered')
    ) {
        throw new Error('Unexpected page data format: Title not specified.');
    }
    if( ! rawPageData.hasOwnProperty('wellonline_content')) {
        throw new Error('Unexpected page data format: Content not specified.');
    }
    if( ! rawPageData.hasOwnProperty('template')) {
        throw new Error('Unexpected page data format: Template not specified.');
    }
    
    /* Extract all of the standard page data properties supported by the app from the raw data,
       skipping those properties that require their own dedicated data extraction mechanism.  */
    const relatedPageProperties = ['child_pages', 'sibling_pages', 'ancestor_pages', 'featured_topics'];
    const skipProperties = ['id', 'title', 'template', ...relatedPageProperties];
    const formattedPageData = extractStandardDataProperties(
        rawPageData, pageInitialData, skipProperties, 'wellonline_'
    );
    
    // Attempt to get the page ID & title from the raw page data.
    formattedPageData.id = rawPageData.id;
    formattedPageData.title = rawPageData.title.rendered ? rawPageData.title.rendered : '';
    
    // Attempt to get the page template from the raw page data.
    formattedPageData.template = extractFormattedPageTemplateName(rawPageData.template);
    
    // Extract formatted data about related pages from the raw data
    for(let i = 0; i < relatedPageProperties.length; i++) {
        let curRelatedPageProperty = relatedPageProperties[i];
        
        // Skip if no data exists for this type of related page
        if( ! rawPageData.hasOwnProperty('wellonline_' + curRelatedPageProperty) ) {
            continue;
        }
    
        let rawRelatedPageData = rawPageData['wellonline_' + curRelatedPageProperty];
        formattedPageData[curRelatedPageProperty] = extractRelatedPagesData(rawRelatedPageData);
    }
    
    return formattedPageData;
}

/**
 * This function converts raw retrieved JSON data containing details about related pages into a simpler format
 * which can then be used throughout the app. Related pages may include child pages, sibling pages etc.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * @param {array} rawRelatedPagesData
 *      Raw data containing details of related pages in JSON format. This may have been retrieved via WP's REST API.
 * @returns {array}
 *      Formatted data for the related pages, which can be used throughout the app as required.
 *
 * @throws {Error}
 *      If the related page data cannot be formatted successfully
 */
const extractRelatedPagesData = (rawRelatedPagesData) => {
    const formattedPagesData = [];
    
    // Check if data for any related pages has been retrieved. Return empty array if not.
    if( ! rawRelatedPagesData || rawRelatedPagesData.length === 0) {
        return formattedPagesData;
    }
    
    // Loop through the retrieved data for each page, so data can be extracted for the correct page (if applicable)
    for(let i = 0; i < rawRelatedPagesData.length; i++) {
        // Get the raw data relating to the current retrieved page
        let rawCurPageData = rawRelatedPagesData[i];
    
        // Check that all the required page data properties exist in the raw data
        if( ! rawCurPageData.hasOwnProperty('id') || ! rawCurPageData.id) {
            throw new Error('Unexpected related page data format: ID not specified.');
        }
        if( ! rawCurPageData.hasOwnProperty('title') ) {
            throw new Error('Unexpected related page data format: Title not specified.');
        }
        
        /* Extract all of the standard related page data properties supported by the app from the raw data,
           skipping those properties that require their own dedicated data extraction mechanism.  */
        const skipProperties = ['link', 'template'];
        const formattedCurPageData = extractStandardDataProperties(
            rawCurPageData, relatedPageInitialData, skipProperties
        );
        
        // Attempt to extract the page link path from the raw page data
        formattedCurPageData.link = extractPagePath(rawCurPageData);
        
        // Extract the page template if available
        if(rawCurPageData.hasOwnProperty('template')) {
            formattedCurPageData.template = extractFormattedPageTemplateName(rawCurPageData.template);
        }
        
        formattedPagesData.push(formattedCurPageData);
    }
    
    return formattedPagesData;
};

/**
 * This function extracts the page path from the supplied page data.
 *
 * The page path is the main page URL, but excluding the http(s):// and the current site domain from the start.
 * For example: '/work-life/bullying/'.
 *
 * @param {Object} pageData
 *      Raw data containing details of the specified page in JSON format. This may have been retrieved via WP's
 *      REST API. This JSON object must include a 'link' property containing the full page URL.
 * @returns {string}
 *      The extracted page path
 *
 * @throws {Error}
 *      If the page path cannot be extracted successfully based on the supplied page data
 */
export const extractPagePath = (pageData) => {
    // Make sure that the raw data for this page has a valid 'link' property
    if( ! pageData.hasOwnProperty('link') || ! pageData.link) {
        throw new Error('Unexpected page data format: Unknown page permalink path');
    }
    
    // Attempt to extract page path from 'link' property, stripping 'http(s)://' and site domain from the start
    return getPagePathFromUrl(pageData.link);
};

/**
 * This function extracts the formatted page template name based on the supplied template name string
 *
 * The '.php' suffix is stripped from the end of the original template name if present. If no template name
 * was specified explicitly in the raw data, the template template name is used instead: 'page-topic'
 *
 * @param {string} template
 *      Original raw page template name. This may have been retrieved for a page via WP's REST API.
 * @returns {string}
 *      The resulting formatted page template name
 */
export const extractFormattedPageTemplateName = (template) => {
    return template !== '' ? template.replace('.php', '') : 'page-topic';
};