/**
 * @todo review & document
 */

import React, {useContext} from 'react';

import './MenuHorizontal.css';

import { IonGrid } from "@ionic/react";
import {AppContext} from "../data/AppContext";
import { Link, useLocation } from "react-router-dom";
import { getMenuItems } from '../helpers/getMenu';
import { sanitiseHtml } from '../helpers/sanitiseHtml';
import { getSectionRootPath } from '../helpers/getPagePaths';
import { changePage } from '../helpers/changePage';

export const MenuHorizontal = () => {
    
    const { state, dispatch } = useContext(AppContext);
    
    const location = useLocation();
    const sectionRootPath = getSectionRootPath(location.pathname);
    
    const menuItems = getMenuItems(state.config.data.menus, state.config.data.menu_locations.header);
    
    return (
            <IonGrid fixed id="site-menu-horizontal" className="ion-text-center">
                <ul>
                    {menuItems.map((item, index) => {
                        return (
                            <li key={item.id}>
                                {item.type !== 'custom' && item.target !== '_blank' ? (
                                    <Link
                                        to={item.link}
                                        onClick={() => changePage(item, state, dispatch)}
                                        className={sectionRootPath === item.link ? 'selected' : ''}
                                    >
                                        <span dangerouslySetInnerHTML={sanitiseHtml(item.title)} />
                                    </Link>
                                ) : (
                                    <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={sectionRootPath === item.link ? 'selected' : ''}
                                    >
                                        <span dangerouslySetInnerHTML={sanitiseHtml(item.title)} />
                                    </a>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </IonGrid>
    );
    
};