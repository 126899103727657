/**
 * @todo review & document
 */

import React from 'react';
import { IonSlide, IonSlides } from '@ionic/react';

import './TopBanner.css';

import topBannerPlaceholderImg from '../images/top-banner-default.jpg';

import { TopBanner } from './TopBanner';

export const TopBannerSlider = ({
    banners
}) => {
    
    const sliderOptions = {
        loop: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false
        }
    };
    
    return (
        <IonSlides pager={true} options={sliderOptions}>
            {banners.map((banner, index) => {
                const topBannerImg = banner.banner_image ? banner.banner_image : topBannerPlaceholderImg;
                return (
                    <IonSlide key={index}>
                        <TopBanner image={topBannerImg} title={banner.title} link={banner.link} useH1={index === 0} />
                    </IonSlide>
                );
            })}
        </IonSlides>
    );
    
};