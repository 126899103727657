/**
 * The functions in this file extract page path information based on a supplied page URL or path.
 * Please see the comment blocks above each function for further details.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/**
 * Attempt to extract the path of an app page from the supplied URL.
 *
 * The page path is the main page URL, but excluding the http(s):// and the current site domain from the start.
 * For example: '/work-life/bullying/'.
 *
 * An error will be thrown if this is unsuccessful.
 *
 * @param {string} url
 *      The full URL of a page, including http(s):// and the current site domain
 *
 * @returns {string}
 *      The resulting page path, e.g. /work-life/bullying/
 *
 * @throws {Error}
 *      If the page path cannot be extracted successfully
 */
export function getPagePathFromUrl(url) {
    if(url.indexOf("http") !== 0) {
        throw new Error('Unexpected URL format');
    }
    
    // Attempt to extract page path from URL, stripping 'http(s)://' and site domain from the start
    const pagePathStartIndex = url.indexOf("/", 8);
    if(pagePathStartIndex === -1) {
        throw new Error('Unexpected page permalink format');
    }
    return getFullPagePathIncSlashes(url.substring(pagePathStartIndex));
}

/**
 * Attempt to extract the path of the root page in the current site section, based on the supplied full page path.
 *
 * This is based on the assumption that the section root page path will be the first segment of the URL up to
 * to the second forward slash, e.g. if the supplied page path is '/work-life/bullying/', the section root page
 * path will be '/work-life/'.
 *
 * An error will be thrown if the section root path cannot be extracted successfully.
 *
 * @param {string} pagePath
 *      The full path of a page, e.g. /work-life/bullying/
 *
 * @returns {string}
 *      The path of the section root page, e.g. /work-life/
 *
 * @throws {Error}
 *      If the section root page path cannot be extracted successfully
 */
export function getSectionRootPath(pagePath) {
    // Prepend and/or append '/' to page path if it is not present already, to ensure consistency
    const fullPagePath = getFullPagePathIncSlashes(pagePath);
    
    // If this is the homepage, simply return the current path as-is, since it is already a section root page
    if(fullPagePath === '/') {
        return fullPagePath;
    }
    
    // Find out the position of the first forward slash ('/'), not including the one at the beginning of the page path
    const rootSectionPathEndIndex = fullPagePath.indexOf("/", 1);
    if(rootSectionPathEndIndex === -1) {
        throw new Error('Unexpected page path format');
    }
    
    // Extract the section root path from the full page path
    return fullPagePath.substring(0, rootSectionPathEndIndex + 1);
}

/**
 * Prepend and/or append a forward slash ('/') to a supplied page path, if forward slashes are not already present
 * at the start and end of this path.
 *
 * This ensures that all page paths follow a consistent format, reducing the risk of errors occurring.
 *
 * @param {string} pagePath
 *      The path of a page, which may or not include forward slashes at the start and end, e.g.
 *      /work-life/bullying/ or
 *      /work-life/bullying or
 *      work-life/bullying/ or
 *      work-life/bullying
 *
 * @returns {string}
 *      The full page path including forward slashes at the start and end, e.g. /work-life/bullying/
 */
export function getFullPagePathIncSlashes(pagePath) {
    // Prepend '/' to beginning of page path if necessary
    if(pagePath.substring(0, 1) !== '/') {
        pagePath = '/' + pagePath;
    }
    
    // Append '/' to end of page path if necessary
    if(pagePath.substring(pagePath.length - 1) !== '/') {
        pagePath = pagePath + '/';
    }
    
    return pagePath;
}

/**
 * Extract page 'slug' from specified page path. The page slug is the string in the final segment of the path, between
 * the last 2 forward slashes (a special page slug is used for the homepage however, based on the slug set up for that
 * page on the current WP site). The page slug is required to retrieve applicable page data from the WP REST API.
 *
 * @param {string} pagePath
 *      Full page path to extract slug from (e.g. '/work-life/bullying/')
 * @param {Object} config
 *      Object containing the current app configuration data
 *
 * @returns {string}
 *      Resulting page slug (e.g. 'bullying'), or an empty string if slug cannot be retrieved
 */
export function extractPageSlug(pagePath, config) {
    if(pagePath === '/') {
        return config.homepage_slug;
    }
    
    const pageSlugStartIndex = pagePath.lastIndexOf("/", pagePath.length - 2) + 1;
    if(pageSlugStartIndex === -1) {
        return '';
    }
    return pagePath.substring(pageSlugStartIndex, pagePath.length - 1);
}