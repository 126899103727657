import { logoutUser } from '../data/user/logoutUser';

/**
 * This function handles any errors relating to the authentication of the current user, based on a response
 * object retrieved via the WP REST API.
 *
 * If the response object contains a 'code' property value prefixed with 'jwt_auth', this indicates that an
 * authentication error was returned from the API, so the user is logged out automatically. Otherwise the
 * response is allowed to be processed as normal.
 *
 * This is an 'async' function, and must be called using an 'await' keyword; this is to allow the user to
 * be safely logged out if required.
 *
 * ----------
 * - Usage: -
 * ----------
 *
 * 1. Import this function at the top of any file that needs to check for any user authentication errors:
 *
 * import { handleUserAuthenticationErrors } from '../../helpers/handleUserAuthenticationErrors';
 *
 * 2. Add code similar to this within the function handling the API response (note the usage of the 'await' keyword):
 *
 * await handleUserAuthenticationErrors(result, dispatch);
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 *
 * @param {object} result
 *      A JS object which has been obtained from a WP REST API response
 * @param {function} dispatch
 *      Dispatch function to use to update global application state if an authentication error response was returned
 */
export async function handleUserAuthenticationErrors(result, dispatch) {
    // Check if the result object contains a 'code' property with a value prefixed with 'jwt_auth'
    if( ! result.hasOwnProperty('code') || result.code.substring(0, 8) !== 'jwt_auth') {
        // If not, the response does not appear to contain any user authentication errors, so proceed as normal
        return;
    }
    
    // Otherwise, a user authentication error has occurred, so log the user out, and throw an error containing details
    await logoutUser(dispatch);
    throw new Error('User authentication error whilst retrieving data: ' + result.code);
}