/**
 * This component renders a helpsheet download button. This button is centred and includes a 'Download' icon.
 * When clicked upon, a PDF helpsheet is opened in a new tab/window, and helpsheet access stats are sent via
 * a request to the WP REST API. Details of the helpsheet to download are retrieved from the global app state
 * (accessible via the AppContext). The component is not rendered if a helpsheet is not available for the
 * current page.
 *
 * The button is rendered using the IonButton component (see https://ionicframework.com/docs/api/button),
 * and is enclosed within a <p> tag.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonButton, IonIcon } from '@ionic/react';

/* Get app context and associated actions, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Import data handling function(s) */
import { sendStatsToApi } from '../data/general/sendStatsToApi';

/* Load Ionicons */
import { download } from 'ionicons/icons';

/* Get component stylesheet(s) */
import './HelpsheetDownloadButton.css';

export const HelpsheetDownloadButton = () => {
    
    // Use app context to retrieve and update the current page data as required
    const {state, dispatch} = useContext(AppContext);
    const {data} = state.page;
    
    // Don't render this component if no helpsheet is available, or if helpsheets are disabled on this page
    if( ! data.help_sheet || data.show_help_sheet === 'no') {
        return null;
    }
    
    // Handle clicks upon the helpsheet download button
    const onHelpsheetDownload = () => {
        // Open helpsheet in a new tab/window
        window.open(data.help_sheet, "_blank");
        
        // Send helpsheet access stats to the WP REST API
        const pageVisitData = {
            page_id: data.id
        }
        sendStatsToApi(pageVisitData, 'helpsheet', state.user.data.token, dispatch);
    }
    
    return (
        <p className="ion-text-center">
            <IonButton
                className="btn-helpsheet-download"
                onClick={onHelpsheetDownload}
            >
                {state.config.data.text.download}
                <IonIcon slot="end" icon={download} />
            </IonButton>
        </p>
    );
    
};