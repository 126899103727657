/**
 * This file implements functionality to retrieve app configuration data in JSON format from WP's REST API.
 *
 * This configuration data (including details of whether data is still being loaded, whether any errors were
 * encountered, and the latest retrieved configuration details) is stored within the overall application state
 * (see 'data/state.js'), and can therefore be retrieved from anywhere in the app where needed.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { initConfigFetch, setConfigFetchError, setConfigFetchSuccess } from './config.actions';

import { extractFormattedConfig } from './extractFormattedConfig';

import ReactGA from 'react-ga';
import { sanitise } from '../../helpers/sanitiseHtml';

/**
 * Asynchronous function to retrieve app configuration data in JSON format from the WP REST API.
 *
 * @param {function} dispatch
 *      Dispatch function to use to update global application state when data retrieval status changes
 *
 * @returns {Promise<void>}
 */
export const fetchAppConfig = async (dispatch) => {
    // Set URL path for REST API request
    const apiUrlPath = '/wp-json/wellonline/v1/config/';
    
    // Update app state to indicate that configuration data fetching is in progress
    dispatch(initConfigFetch());
    
    try {
        // Wait for JSON data response to be retrieved from URL
        const response = await fetch(apiUrlPath);
        const result = await response.json();
        
        // Convert retrieved data into expected format
        const config = extractFormattedConfig(result);
        
        // Update app state to keep track of the successfully retrieved data
        dispatch(setConfigFetchSuccess(config));
    
        // Set up the Google Analytics tracking mechanism, using the retrieved configuration data
        setUpGoogleAnalytics(config);
    
        // Update page title
        if(config.title) {
            // Use this method to ensure HTML entities are correctly decoded, whilst minimising security risks
            document.querySelector('title').innerHTML = sanitise(config.title);
        }
    } catch (error) {
        // Update app state to indicate that errors occurred when retrieving data
        dispatch(setConfigFetchError());
        // Log any data retrieval errors to browser console
        console.error(error);
    }
};

/**
 * Set up the Google Analytics tracking mechanism, using the GA tracking code retrieved from the configuration data.
 *
 * Browser console logging of the tracked data will be enabled if the 'REACT_APP_ENABLE_GOOGLE_ANALYTICS_LOGGING'
 * environment variable is set to '1'.
 *
 * The mechanism to actually send tracked data to Google Analytics (e.g. when pages are viewed) is
 * implemented elsewhere.
 *
 * @param {Object} config
 *      Configuration object, previously retrieved from the WP REST API.
 */
const setUpGoogleAnalytics = (config) => {
    if( ! config.ga_code) {
        return;
    }
    
    let enableLogging = false;
    if(
        process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS_LOGGING &&
        process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS_LOGGING === "1"
    ) {
        enableLogging = true;
    }
    
    ReactGA.initialize(config.ga_code, { debug: enableLogging });
}