/**
 * The initial state relating to the search functionality is defined within this file. This is used by the
 * 'state.js' file to define the overall initial application state, which ensures the app runs smoothly before
 * all data has been fully populated.
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export const searchInitialState = {
    showSearchBar: true,
    showSearchBtn: false,
    query: '',
    prepopulateQuery: true,
    isLoadingNewSearch: false,
    isLoadingNextPage: false,
    isError: false,
    pageNum: 1,
    totalPages: 1,
    results: []
};