/**
 * The functions in this file provide functionality to store and retrieve data within browser cookies, as well as
 * to remove cookies that are no longer needed.
 *
 * These functions are based on the sample code at: https://www.quirksmode.org/js/cookies.html
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/**
 * Store a cookie in the user's browser
 *
 * @param {string} name
 *     The name of the cookie to store
 * @param {string} value
 *     The value to store in the relevant cookie
 * @param {number} expiryDays
 *     The number of days before the cookie will expire, e.g 90
 */
export function setCookie(name, value, expiryDays) {
    let expires = '';
    if (expiryDays) {
        const date = new Date();
        date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    }
    
    document.cookie = name + '=' + value + expires + '; path=/';
}

/**
 * Retrieve the value of a specified cookie that was previously stored by the user's browser
 *
 * @param {string} name
 *      The name of the cookie to retrieve
 *
 * @returns {string|null}
 *      The retrieved cookie value, or NULL if no cookie value could be retrieved (e.g. if the cookie does not exist)
 */
export function getCookie(name) {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(';');
    
    for(let i=0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1, cookie.length);
        }
        
        if (cookie.indexOf(nameEQ) === 0)  {
            return cookie.substring(nameEQ.length, cookie.length);
        }
    }
    
    return null;
}

/**
 * Remove the specified cookie from the user's browser.
 *
 * This is achieved by updating the relevant cookie to use an expiry date that is set in the past.
 *
 * @param {string} name
 *      The name of the cookie to remove
 */
export function removeCookie(name) {
    setCookie(name, '', -1);
}