/**
 * This script sets up a React Context (see https://reactjs.org/docs/context.html) which allows the app state
 * to be managed and updated where necessary throughout the app.
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is loosely based on the code in the 'Ionic Music Player React Demo' app
 * (see https://stackblitz.com/edit/ionic-react-demo?file=State.jsx), but refactored to use
 * a structure more similar to the code in the 'Ionic Conference App' template (see
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/AppContext.tsx).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import React, { createContext, useReducer } from 'react';
import { appInitialState, loggedReducers } from './state'

export const AppContext = createContext({
  state: appInitialState,
  dispatch: () => undefined
});

export const AppContextProvider = (props => {

  const [store, dispatch] = useReducer(loggedReducers, appInitialState);

  return (
    <AppContext.Provider value={{
      state: store,
      dispatch
    }}>
      {props.children}
    </AppContext.Provider>
  )
});