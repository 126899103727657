/**
 * @todo review & document
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonGrid } from '@ionic/react';

/* Get app context, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get the app components that are used by this page content template */
import TopicCards from '../components/TopicCards';
import { Quote } from '../components/Quote';
import { BackgroundShape } from '../components/BackgroundShape';
import { TopContent } from '../components/TopContent';

export const TopicListingTemplate = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    const {page} = state;
    
    return (
        <div className='content-container'>
            
            <BackgroundShape position="left" contentTop={true} hideOnSmallScreens={true} />
            <BackgroundShape position="right" contentTop={true} hideOnLargeScreens={true} />
            
            <IonGrid fixed>

                { ! page.isLoading && page.data.content.length > 0 && (
                    <TopContent />
                )}
    
                <Quote quote={page.data.quote} image={page.data.quote_image} isLoading={page.isLoading} />
                
            </IonGrid>

            <TopicCards
                heading={state.config.data.text.topic_listings_title}
                topics={page.data.child_pages}
                isLoading={page.isLoading}
            />
        </div>
    );
};
