/**
 * @todo review & document
 */
import React, { useContext, useEffect, useRef } from 'react';
import {
    IonContent,
    IonGrid,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonPage,
    useIonViewDidEnter
} from '@ionic/react';
import { useLocation } from 'react-router';

import { AppContext } from '../data/AppContext';
import { setSearchQuery } from '../data/search/search.actions';
import { fetchSearchResultsFromApi } from '../data/search/fetchSearchResultsFromApi';

import { useAutoscrollToTopOnLoad } from '../hooks/useAutoscrollToTopOnLoad';

import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { TopBanner } from '../components/TopBanner';
import { SearchResults } from '../components/SearchResults';
import { BackgroundShape } from '../components/BackgroundShape';

import { sanitise, sanitiseHtml } from '../helpers/sanitiseHtml';

import topBannerPlaceholderImg from '../images/top-banner-default.jpg';

export const SearchPage = () => {
    // Use app context to handle state management & updates
    const {state, dispatch} = useContext(AppContext);
    const {search} = state;
    
    // Update page title
    useIonViewDidEnter(() => {
        if(state.config.data.title && state.config.data.text.search_title) {
            // Use this method to ensure HTML entities are correctly decoded, whilst minimising security risks
            const pageTitle = state.config.data.title + ' - ' + state.config.data.text.search_title;
            document.querySelector('title').innerHTML = sanitise(pageTitle);
        }
    });
    
    
    /* If the search page URL includes a search query, but the app state does not yet, update the app state to
       include this search query text. This is to ensure that search results will be retrieved upon initial page
       load if necessary, based on the search query in the URL. It also ensures that the search bar text will be
       pre-populated to contain the relevant search text. This functionality is designed to only execute once
       if we are on a search page when the app first loads. */
    const location = useLocation();
    const searchQuery = decodeURIComponent(location.search.replace('?query=', ''));
    useEffect(() => {
        /* Only update app state if search text could be retrieved from the URL and the 'prepopulateQuery' option
           is switched on i.e. only upon initial app load. */
        if(searchQuery.length > 0 && search.prepopulateQuery) {
            dispatch(setSearchQuery(searchQuery));
        }
    }, [searchQuery, search.prepopulateQuery, dispatch]);
    
    // When the search query stored in the app state is changed, retrieve new search results from the WP REST API
    useEffect(() => {
        fetchSearchResultsFromApi(search.query, 1, state.user.data.token, dispatch);
    }, [search.query, state.user.data.token, dispatch]);
    
    // When the results for a new search query are being loaded, scroll back to the top of the results page
    const {contentRef} = useAutoscrollToTopOnLoad(search.isLoadingNewSearch);
    
    /* 'Infinite scroll' functionality: When the user reaches the bottom of the page, retrieve the next 'page'
       of results for this search query from the WP REST API */
    const infiniteScrollRef = useRef(null);
    function onInfiniteScroll(e) {
        fetchSearchResultsFromApi(search.query, search.pageNum + 1, state.user.data.token, dispatch);
    }
    
    // After the next 'page' of results has been successfully retrieved, hide the 'infinite scroll' animation
    useEffect(() => {
        if(
            ! search.isLoadingNextPage &&
            infiniteScrollRef.current &&
            typeof infiniteScrollRef.current.complete !== 'undefined'
        ) {
            infiniteScrollRef.current.complete();
        }
    }, [search.isLoadingNextPage]);
    
    // Load the default top banner image from the app config if available, or use a placeholder image if not
    const topBannerImg = state.config.data.banner_image ? state.config.data.banner_image : topBannerPlaceholderImg;
    
    return (
        <IonPage>
            <Header />
            
            <IonContent ref={contentRef} scrollEvents={true}>
                <TopBanner title={state.config.data.text.search_title} image={topBannerImg}  />
    
                <div className='content-container'>
    
                    <BackgroundShape position="left" hideOnSmallScreens={true} />
                    <BackgroundShape position="right" />
                    
                    {search.query.length > 0  && ! search.isError ? (
                        <>
                            <SearchResults
                                query={search.query}
                                results={search.results}
                                isLoading={search.isLoadingNewSearch}
                            />
        
                            <IonInfiniteScroll
                                threshold="600px"
                                ref={infiniteScrollRef}
                                onIonInfinite={onInfiniteScroll}
                                disabled={search.pageNum >= search.totalPages && ! search.isLoadingNextPage}
                            >
                                 <IonInfiniteScrollContent
                                     loadingSpinner="bubbles"
                                     loadingText={state.config.data.text.search_loading_message}
                                 >
                                 </IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </>
                    ) :
                        search.query.length === 0 ? (
                            <IonGrid fixed className="ion-text-center">
                                <h2>{state.config.data.text.search_empty_field_title}</h2>
                                <p>{state.config.data.text.search_empty_field_message}</p>
                            </IonGrid>
                        ) : (
                            <IonGrid fixed className="ion-text-center">
                                <h1 className="error-message">{state.config.data.text.search_load_error_title}</h1>
                                {state.config.data.text.search_load_error_message ?
                                (
                                    <div
                                        dangerouslySetInnerHTML={sanitiseHtml(
                                            state.config.data.text.search_load_error_message)}
                                    />
                                ) : (
                                    <>
                                        <p>
                                            Apologies, the search results did not load successfully.
                                            Please try the following steps:
                                        </p>
                                        <p>
                                            1. Check whether you are currently connected to the internet.
                                            If not, connect now and then reload this page.
                                        </p>
                                        <p>
                                            2. Try searching for something different using the search bar
                                            at the top.
                                        </p>
                                    </>
                                )}
                            </IonGrid>
                        )
                    }
                    
                </div>
                
                <Footer />
            </IonContent>
        </IonPage>
    );
};