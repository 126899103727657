/**
 * @todo review & document
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonGrid } from '@ionic/react';

/* Get app context, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get the app components that are used by this page content template */
import { SkeletonTextParagraphs } from '../components/SkeletonTextParagraphs';
import { BackgroundShape } from '../components/BackgroundShape';

/* Import app helper function(s) */
import { sanitiseHtmlWithShortcodes } from '../helpers/sanitiseHtml';

export const BasicTemplate = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    const {page} = state;
  
    return (
        <div className="content-container">
    
            <BackgroundShape position="left" hideOnSmallScreens={true} />
            <BackgroundShape position="right" />
            
            <IonGrid fixed>
                { ! page.isLoading ? (
                    <div
                        className="page-content"
                        dangerouslySetInnerHTML={sanitiseHtmlWithShortcodes(page.data.content, state)}
                    />
                ) : (
                    <SkeletonTextParagraphs number="4" />
                )}
            </IonGrid>
            
        </div>
    );
};
