/**
 * This file contains functionality to extract raw search results JSON data (which has already been retrieved e.g.
 * from WP's REST API), converting it into a simpler format which can then be used throughout the app. If the raw
 * search results cannot be extracted/formatted correctly, an error will be thrown - this then needs to be handled
 * elsewhere in the app.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import { relatedPageInitialData } from '../page/pageData.init';
import { getPagePathFromUrl } from '../../helpers/getPagePaths';
import { extractFormattedPageTemplateName } from '../page/extractFormattedPageData';
import { extractStandardDataProperties } from '../general/extractStandardDataProperties';

/**
 * This function extracts raw search results JSON data (which has already been retrieved e.g. from WP's REST API),
 * converting it into a simpler format which can then be used throughout the app.
 *
 * If the raw data cannot be extracted/formatted correctly, an error is thrown.
 *
 * @param {array} rawSearchResults
 *      Raw search results in JSON format. This may have been retrieved e.g. from WP's REST API.
 * @returns {Object}
 *      Formatted search results which can be used throughout the app as required.
 *
 * @throws {Error}
 *      If the search results cannot be extracted/formatted successfully
 */
export const extractFormattedSearchResults = (rawSearchResults) => {
    const formattedSearchResults = [];
    const skipProperties = ['id', 'title', 'link', 'template'];
    
    // Check if any search results have been retrieved. Return empty array if not.
    if( ! rawSearchResults || rawSearchResults.length === 0) {
        return formattedSearchResults;
    }
    
    // Loop through the retrieved search results, so data can be extracted for each search result
    for(let i = 0; i < rawSearchResults.length; i++) {
        // Get the raw data relating to the current retrieved search result
        let rawCurSearchResult = rawSearchResults[i];
    
        // Check that all the required search result properties exist in the raw data
        if( ! rawCurSearchResult.hasOwnProperty('id') || ! rawCurSearchResult.id) {
            throw new Error('Unexpected search result format: ID not specified.');
        }
        if( ! rawCurSearchResult.hasOwnProperty('title') ) {
            throw new Error('Unexpected search result format: Title not specified.');
        }
        if( ! rawCurSearchResult.hasOwnProperty('url') || ! rawCurSearchResult.url) {
            throw new Error('Unexpected search result format: URL not specified.');
        }
    
        /* Extract all of the standard search result properties supported by the app from the raw data,
           skipping those properties that require their own dedicated data extraction mechanism.  */
        const formattedCurSearchResult = extractStandardDataProperties(
            rawCurSearchResult,
            relatedPageInitialData,
            skipProperties,
            'wellonline_'
        );
        
        // Extract the relevant page ID and title as-is (these fields are not prefixed with 'wellonline_')
        formattedCurSearchResult.id = rawCurSearchResult.id;
        formattedCurSearchResult.title = rawCurSearchResult.title ? rawCurSearchResult.title : '';
        
        // Attempt to extract the page link path from the raw search result data
        formattedCurSearchResult.link = getPagePathFromUrl(rawCurSearchResult.url);
        
        // Extract the page template associated with this search result if available
        if(rawCurSearchResult.hasOwnProperty('wellonline_template')) {
            formattedCurSearchResult.template =
                extractFormattedPageTemplateName(rawCurSearchResult.wellonline_template);
        }
        
        formattedSearchResults.push(formattedCurSearchResult);
    }
    
    return formattedSearchResults;
};