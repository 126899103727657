/**
 * This Reducer function specifies how the app state should be transformed when data relating to the search
 * functionality is updated; for example when search results are being retrieved from the WP REST API (see the
 * 'fetchSearchResultsFromApi()' function), based on the specified action type. The function creates and returns
 * a new version of the 'state' variable, based on the supplied action object data (see the search.actions.js
 * file for the defined action objects which relate to the search functionality).
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on a simplified version of the reducers in the 'Ionic Conference App' template (see
 * e.g. https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/user/user.reducer.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export function searchReducer(state, action) {
    switch (action.type) {
        case 'HIDE_SEARCH_BAR':
            return {
                ...state,
                showSearchBar: false,
                showSearchBtn: true
            };
        case 'SHOW_SEARCH_BAR':
            return {
                ...state,
                showSearchBar: true
            };
        case 'SET_SEARCH_QUERY':
            return {
                ...state,
                query: action.payload,
                prepopulateQuery: false,
                isLoadingNewSearch: true
            };
        case 'SEARCH_RESULTS_FETCH_NEW_INIT':
            return {
                ...state,
                isLoadingNewSearch: true,
                isLoadingNextPage: false,
                pageNum: 1,
                totalPages: 1,
                isError: false,
                results: []
            };
        case 'SEARCH_RESULTS_FETCH_PAGE_INIT':
            return {
                ...state,
                isLoadingNewSearch: false,
                isLoadingNextPage: true,
                pageNum: action.pageNum,
                isError: false,
            };
        case 'SEARCH_RESULTS_FETCH_SUCCESS':
            return {
                ...state,
                isLoadingNewSearch: false,
                isLoadingNextPage: false,
                isError: false,
                results: state.results.concat(action.payload),
                totalPages: action.totalPages
            };
        case 'SEARCH_RESULTS_FETCH_ERROR':
            return {
                ...state,
                isLoadingNewSearch: false,
                isLoadingNextPage: false,
                isError: true,
            };
        default:
            // Intentionally left empty - further action types may exist in other reducers
        break;
    }
}