/**
 * This Reducer function specifies how the app state should be transformed when data relating to the current app
 * configuration is updated; for example when data is being retrieved from the WP REST API (see the fetchAppConfig()
 * function), based on the specified action type. The function creates and returns a new version of the 'state'
 * variable, based on the supplied action object data (see the config.actions.js file for the defined action objects
 * which relate to the fetching of app config data).
 *
 * This forms part of the simple Redux-like state management pattern for React which is implemented for this app
 * using hooks. This solution is based on Ionic's suggested mechanism for managing app state - see:
 * https://ionicframework.com/blog/a-state-management-pattern-for-ionic-react-with-react-hooks/
 *
 * The code below is based on a simplified version of the reducers in the 'Ionic Conference App' template (see e.g.
 * https://github.com/ionic-team/ionic-react-conference-app/blob/master/src/data/user/user.reducer.ts).
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

export function configReducer(state, action) {
    switch (action.type) {
        case 'CONFIG_FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case 'CONFIG_FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload
            };
        case 'CONFIG_FETCH_ERROR':
            return {
                ...state,
                isLoading: false,
                isError: true
            };
        case 'CONFIG_LIVE_CHAT_ENABLED':
            return {
                ...state,
                liveChatEnabled: true
            };
        case 'CONFIG_LIVE_CHAT_DISABLED':
            return {
                ...state,
                liveChatEnabled: false
            };
        default:
            // Intentionally left empty - further action types may exist in other reducers
        break;
    }
}