/**
 * This component renders 'cards' to display for the specified topics. Each card includes a title, brief excerpt,
 * and button; and the card links to another page within the app. The cards are displayed within a 4 column grid
 * (the cards stretch horizontally to fit the available space if there are less than 4 cards in a column), which
 * is horizontally centred within the page content. This grid is surrounded by a full-width grey background, and
 * a heading is displayed at the top. If content is still loading, 4 cards containing 'skeleton' content will be
 * displayed instead.
 *
 * Cards are rendered using the IonCard component (see https://ionicframework.com/docs/api/card), and the
 * IonGrid component is used to render the grid and to centre this horizontally within the page content (see
 * https://ionicframework.com/docs/api/grid and https://ionicframework.com/docs/layout/grid).
 *
 * See the code comments below for details of the 'props' that must be passed to this component.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

/* Get React/Ionic dependencies */
import React from 'react';
import { IonGrid, IonRow } from '@ionic/react';

/* Get required custom app components */
import { TopicCard } from './TopicCard';
import { TopicCardLoading } from './TopicCardLoading';

/* Import app helper function(s) */
import { sanitiseHtml } from '../helpers/sanitiseHtml';

/**
 *  The following 'props' must be passed to this component:
 *
 * @param {string} heading
 *      The textual content to display in the top heading. This text can include basic HTML.
 * @param {array} topics
 *      Array of JSON objects containing the details to display in each topic card - including title, excerpt,
 *      page link path, and content template to use if navigating to a new page. For example:
 *
 *      [
 *          {
 *              'title': 'Bullying in the Workplace',
 *              'link': '/work-life/bullying/bullying-in-the-workplace/',
 *              'excerpt': 'Some excerpt text ...',
 *              'template': 'page-topic'
 *           },
 *           {
 *              'title': 'A bully or a strong manager?',
 *              'link': '/work-life/bullying/a-bully-or-a-strong-manager/',
 *              'excerpt': 'Some other excerpt text ...',
 *              'template': 'page-topic'
 *           }
 *       ]
 *
 * @param {boolean} isLoading
 *      Is the page content still loading? If so, 4 cards containing 'skeleton' content will be displayed instead
 */
const TopicCards = (
    {
        heading,
        topics,
        isLoading
    }
) => {
    
    const totalNumCols = 4; const rows = [];
    
    // Assemble the topic cards to display if content is not still loading
    if( ! isLoading) {
        // Do not render any topic cards if there are no topics
        if(topics.length === 0) {
            return null;
        }
    
        // Calculate the number of rows to render, based on the number of topics
        const totalNumRows = Math.ceil(topics.length / totalNumCols);
    
        // Loop through each row and column, assembling the topic card to render in each grid 'cell'
        let curTopicNum = 0;
        for(let curRowNum = 0; curRowNum < totalNumRows; curRowNum++) {
            // Adjust topic card widths at medium screen sizes, based on number of topics & if final row is being shown
            let sizeMd = 3;
            if((curRowNum + 1) === totalNumRows) {
                let numRemainingTopics = (topics.length - curTopicNum);
                sizeMd = 12 / numRemainingTopics;
            }
            
            let cols = []; // Reset the content to render in the columns for the next row
            for(let curColNum = 0; curColNum < totalNumCols; curColNum++) {
                // Adjust width of topic cards shown at small screen sizes, based on number of remaining topics
                let sizeSm = 6; let numRemainingTopics = (topics.length - curTopicNum);
                if(numRemainingTopics <= 1) {
                    sizeSm = topics.length % 2 === 0 ? 6 : 12;
                }
                
                // Do not assemble any further cards if we have already assembled cards for all available topics
                if(curTopicNum === topics.length) {
                    break;
                }
            
                // Get the next available topic
                let topic = topics[curTopicNum];
            
                // Assemble the next topic card and add it to the array of assembled columns within the current row.
                cols.push(
                    <TopicCard key={topic.id} topic={topic} image={false} sizeSm={sizeSm} sizeMd={sizeMd} />
                );
            
                // Proceed to the next available topic
                curTopicNum++;
            }
        
            // Assemble the next row based on the assembled columns, and add this to the array of assembled rows.
            rows.push(
                <IonRow key={curRowNum}>
                    {cols}
                </IonRow>
            );
        }
    } else {
        // Otherwise, if content is still loading, assemble 4 topic cards containing skeleton content
        let cols = [];
        for(let curTopicNum = 0; curTopicNum < totalNumCols; curTopicNum++) {
            cols.push(
                <TopicCardLoading key={curTopicNum} hasImage={false} sizeSm={3} sizeMd={3} />
            );
        }
        rows.push(
            <IonRow key="0">
                {cols}
            </IonRow>
        );
    }
    
    // Render assembled topic card rows in a grid, surrounded by a full-width grey background, with a top heading
    return (
        <div className="bg-light topic-cards">
            <IonGrid fixed>
                <h2 className="ion-text-center"><strong dangerouslySetInnerHTML={sanitiseHtml(heading)} /></h2>
        
                <IonGrid>
                    {rows}
                </IonGrid>
            </IonGrid>
        </div>
    );
    
};

export default TopicCards;