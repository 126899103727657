/**
 * @todo review & document
 */

import React, {useContext} from 'react';
import { Link } from "react-router-dom";

import {AppContext} from "../data/AppContext";

import { sanitiseHtml } from '../helpers/sanitiseHtml';
import { changePage } from '../helpers/changePage';

export const MenuVertical = ({
    heading,
    menuItems
}) => {
    
    const { state, dispatch } = useContext(AppContext);
    
    return (
        <>
            {menuItems.length > 0 && (
                <h2>{heading}</h2>
            )}
            <ul>
                {menuItems.map((item, index) => {
                    return (
                        <li key={item.id}>
                            {item.type !== 'custom' && item.target !== '_blank' ? (
                                <Link
                                    to={item.link}
                                    onClick={() => changePage(item, state, dispatch)}
                                >
                                    <span dangerouslySetInnerHTML={sanitiseHtml(item.title)} />
                                </Link>
                            ) : (
                                 <a href={item.link} target="_blank" rel="noopener noreferrer">
                                     <span dangerouslySetInnerHTML={sanitiseHtml(item.title)} />
                                 </a>
                             )}
                        </li>
                    );
                })}
            </ul>
        </>
    );
    
};