/**
 * This code implements an Error Boundary component - see https://reactjs.org/docs/error-boundaries.html for more
 * details, and to see the sample code that this component is based upon.
 *
 * This Error Boundary surrounds all app components (see `App.jsx`). If a JS error occurs inside any app component,
 * this component will render a special error page (see the separate `ComponentErrorPage` component), which provides
 * useful advice to help the user resolve the issues when displaying the app. Otherwise all the child components of
 * this component will be rendered, to ensure the app is displayed as normal.
 *
 * This component is only intended as a 'worst case' app error handling mechanism, since displaying this error page
 * significantly disrupts the user experience - wherever possible, errors should instead be handled more seamlessly
 * within the relevant app code, and/or functionality should be implemented in such a way that errors will never
 * occur in the first place.
 *
 * @category   GenerateUK
 * @package    wellonline-pwa
 * @author     Patrick Hathway - Generate UK
 */

import React from 'react';
import { ComponentErrorPage } from './pages/ComponentErrorPage';

export class ErrorBoundary extends React.Component {
    
    /**
     * Initialise this class
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    
    /**
     *  Update state so that an Error Page can be displayed when this component next renders.
     *
     * @param error
     * @returns {{hasError: boolean}}
     */
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    
    /**
     * Log details of the error
     *
     * @param error
     * @param errorInfo
     */
    componentDidCatch(error, errorInfo) {
        console.error('A component error occurred: ' + error);
        console.error(errorInfo);
    }
    
    /**
     * When this component is rendered, all the child components will get rendered unless an error occurred.
     * If an error occurred, instead render the `ComponentErrorPage` component.
     *
     * @returns {React.ReactNode|*}
     */
    render() {
        if (this.state.hasError) {
            return <ComponentErrorPage />;
        }
        
        return this.props.children;
    }
}