/**
 * @todo review & document
 */

/* Get React/Ionic dependencies */
import React, { useContext } from 'react';
import { IonCol, IonGrid, IonImg, IonRow, IonSkeletonText } from '@ionic/react';

/* Get app context, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get the app components that are used by this page content template */
import TopicCards from '../components/TopicCards';
import { SkeletonTextParagraphs } from '../components/SkeletonTextParagraphs';
import { HelpsheetDownloadButton } from '../components/HelpsheetDownloadButton';

/* Import app helper function(s) */
import { sanitiseHtml, sanitiseHtmlWithShortcodes } from '../helpers/sanitiseHtml';

/* Import image file asset(s) used by this template */
import topicPlaceholderImg from '../images/featured-img-default.jpg';

export const TopicTemplate = () => {
    
    // Use app context to retrieve the current page data
    const {state} = useContext(AppContext);
    const {page} = state;
    
    // Dynamically replace any shortcodes within the content
    const topicCardsTitle = state.config.data.text.related_topic_listings_title.replace(
        '[TOPIC_TITLE]', page.data.title
    );
    
    return (
        <div className='content-container'>
            <IonGrid fixed>
                <h2 className="ion-text-center">
                    <strong dangerouslySetInnerHTML={sanitiseHtml(page.data.title)} />
                </h2>
    
                <IonGrid>
                    <IonRow>
                        <IonCol size-xs="12" size-lg="6" className="page-content">
                            { ! page.isLoading ? (
                                <div dangerouslySetInnerHTML={sanitiseHtmlWithShortcodes(
                                    page.data.content,
                                    state
                                )} />
                            ) : (
                                <SkeletonTextParagraphs number="4" />
                            )}
                        </IonCol>
                        <IonCol size-xs="12" size-lg="6">
                            { ! page.isLoading ? (
                                <>
                                    <IonImg
                                        src={page.data.featured_image ? page.data.featured_image : topicPlaceholderImg}
                                        alt={page.data.title}
                                    />

                                    <HelpsheetDownloadButton />
                                </>
                            ) : (
                                <IonSkeletonText animated style={{ height: '300px' }} />
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonGrid>

            <TopicCards
                heading={topicCardsTitle}
                topics={page.data.sibling_pages}
                isLoading={page.isLoading}
            />
        </div>
    );
};
