/**
 * @todo review & document
 */
import { IonContent, IonPage } from '@ionic/react';
import React, { useContext } from 'react';
import { useLocation } from 'react-router';

import { AppContext } from '../data/AppContext';

import { usePageData } from '../hooks/usePageData';
import { useAutoscrollToTopOnLoad } from '../hooks/useAutoscrollToTopOnLoad';

import { TemplateLoader } from '../templates/TemplateLoader';

import { TopBanners } from '../components/TopBanners';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Breadcrumbs } from '../components/Breadcrumbs';

export const Page = () => {
    // Get data to use for the page whenever the URL path changes
    usePageData();
    
    // Auto-scroll back to the top of the page content area when new content is being loaded
    const {state} = useContext(AppContext);
    const {contentRef, onScroll} = useAutoscrollToTopOnLoad(state.page.isLoading);
    
    // Don't render page if we're on the 'search' page (workaround for `IonRouterOutlet` not supporting `Switch`)
    const { pathname } = useLocation();
    if(pathname.substring(0, 7) === '/search') {
        return null;
    }
    
    return (
        <IonPage>
            <Header />
            
            <IonContent ref={contentRef} scrollEvents={true} onIonScroll={onScroll}>
                <TopBanners />
                <Breadcrumbs />
                <TemplateLoader />
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default Page;
