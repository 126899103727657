/**
 * @todo review & document
 */

/* Get React/React Router dependencies */
import React, { useContext } from 'react';
import { useLocation } from 'react-router';

/* Get app context, so the app state can be retrieved and/or updated as required */
import { AppContext } from '../data/AppContext';

/* Get the page content template components */
import { ErrorTemplate } from './ErrorTemplate';
import { HomeTemplate } from './HomeTemplate';
import { SectionRootTemplate } from './SectionRootTemplate';
import { TopicListingTemplate } from './TopicListingTemplate';
import { TopicTemplate } from './TopicTemplate';
import { ContentIsLoadingTemplate } from './ContentIsLoadingTemplate';
import { BasicTemplate } from './BasicTemplate';

export const TemplateLoader = () => {
    const { state } = useContext(AppContext);
    const { page } = state;
    
    const { pathname } = useLocation();
    
    if(page.isError) {
        return <ErrorTemplate />;
    }
    
    if(pathname === '/') {
        return <HomeTemplate />;
    }
    
    switch(page.data.template) {
        case 'page-section-root':
            return <SectionRootTemplate />;
    
        case 'page-topic-listing':
            return <TopicListingTemplate />;
            
        case 'page-topic':
            return <TopicTemplate />;
    
        case 'page-basic':
            return <BasicTemplate />;
            
        case '':
            return <ContentIsLoadingTemplate />;
            
        default:
            return <ErrorTemplate />;
    }
};